import React, { useContext, useEffect, useRef, useState } from "react";
import CriticalErrorContext from "../../../context/critial-error/critial-error-context";

//Components
import { Typography } from "@material-ui/core";

const GQLQueryErrorHandler = ({ error }) => {
  const criticalErrorCtx = useRef(useContext(CriticalErrorContext));
  const [msg, setMsg] = useState("Something went wrong");

  useEffect(() => {
    if (error) {
      if (error.networkError) {
        console.log(error.networkError);
        criticalErrorCtx.current.setError(
          error.message,
          "Something went wrong"
        );
      }

      if (error.graphQLErrors.length > 0) {
        criticalErrorCtx.current.setError(error.graphQLErrors[0].message);
        setMsg(error.graphQLErrors[0].message);
      }
    }
  }, [error]);
  if (!error) return null;

  return <Typography>{msg}</Typography>;
};

export default GQLQueryErrorHandler;
