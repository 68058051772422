import React, { Fragment } from "react";

//Css
import ss from "../PlaceConfigForm.module.css";

//Components
import ValidatedSelect from "../../../../../UI/Form/ValidatedSelect/ValidatedSelect";
import ValidatedTextField from "../../../../../UI/Form/ValidatedTextField/ValidatedTextField";
import { MenuItem } from "@material-ui/core";

const LDDQ = (props) => {
  return (
    <Fragment>
      <div className={ss.selectContainer}>
        <ValidatedSelect
          name={props.valType}
          label="Device Type"
          type="select"
          className={ss.select}
          disabled={props.disabled}
        >
          <MenuItem value="WATTECO_TEMP">WATTECO_TEMP</MenuItem>
          <MenuItem value="ALMIND_ADENUIS_TEMP">ALMIND_ADENUIS_TEMP</MenuItem>
          <MenuItem value="AQUATROLL_TEMP">AQUATROLL_TEMP</MenuItem>
          <MenuItem value="TEMP_CABLE_TEMP">TEMP_CABLE_TEMP</MenuItem>
          <MenuItem value="WSP_TEMP">WSP_TEMP</MenuItem>
          <MenuItem value="GENERIC_TEMP_2D">GENERIC_TEMP_2D</MenuItem>
          <MenuItem value="GENERIC_TEMP_3D">GENERIC_TEMP_3D</MenuItem>
        </ValidatedSelect>
      </div>
      <ValidatedTextField
        name={props.valId}
        type="input"
        label="ID"
        placeholder="Dev EUI"
        className={ss.input}
        fullWidth
        disabled={props.disabled}
      />
    </Fragment>
  );
};

export default LDDQ;
