import React, { useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { useHistory } from "react-router";

//Components
import Section from "../../../../UI/Section/Section";
import UserRow from "./Layout/UserRow/UserRow";
import GQLQueryErrorHandler from "../../../../UI/GQLQueryErrorHandler/GQLQueryErrorHandler";

const UserList = (props) => {
  const history = useHistory();

  const [queryUsers, { loading, error, data }] = useLazyQuery(ALL_USERS_QUERY, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    queryUsers();
  }, [queryUsers]);

  const onEditUser = (id, e) => {
    history.push(`/users/${id}`);
  };

  return (
    <Section title="Users">
      <GQLQueryErrorHandler error={error} />
      {loading && !error ? <p>Loading</p> : null}
      {data &&
        data.users.map((user) => (
          <UserRow
            key={user.id}
            firstName={user.firstName}
            lastName={user.lastName}
            onEdit={onEditUser.bind(null, user.id)}
          />
        ))}
    </Section>
  );
};

export default UserList;

export const ALL_USERS_QUERY = gql`
  query allUsers {
    users {
      firstName
      lastName
      id
    }
  }
`;
