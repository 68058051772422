import React from "react";
import { v4 as uuidv4 } from "uuid";
import { FieldArray } from "formik";
import ActionsLayout from "../ActionLayout/ActionsLayout";
import DataPoint from "./Layout/DataPoint";

const hasError = (index, errors) => {
  if (!errors || errors.length === 0) return false;

  if (index > errors.length) return false;

  if (errors[index] == null) return false;

  if (errors[index].value) return false;

  return true;
};

const DataPointsArray = (props) => {
  return (
    <FieldArray name={props.arrayName}>
      {(helpers) => {
        const onDelete = (index) => {
          helpers.remove(index);
        };

        const onAdd = () => {
          helpers.push(getInitItem());
        };

        return (
          <ActionsLayout onAdd={onAdd} label="Add Datapoint">
            {props.items.map((dp, index) => {
              return (
                <DataPoint
                  key={dp.id}
                  arrayName={props.arrayName}
                  index={index}
                  onDelete={onDelete.bind(null, index)}
                  hasError={hasError(index, props.errors)}
                />
              );
            })}
          </ActionsLayout>
        );
      }}
    </FieldArray>
  );
};

export default DataPointsArray;

export const getInitItem = () => {
  return {
    id: uuidv4(),
    label: "",
    unit: "",
    value: "",
    quality: "",
    rangeMin: "",
    rangeMax: "",
    limitMin: "",
    limitMax: "",
  };
};
