import { Button, Typography } from "@material-ui/core";
import React from "react";
import Card from "../../../../../UI/Card/Card";
import defaultPlaceImage from "../../../../../../assets/default-place-image.jpg";

//CSS
import ss from "./PlaceCard.module.css";

const PlaceCard = (props) => {
  let imageSrc = defaultPlaceImage;
  if (props.imagePath !== null) {
    imageSrc = `${process.env.REACT_APP_BACKEND_IMAGES_LOAD}/${props.imagePath}`;
  }
  return (
    <Card className={ss.card}>
      <img src={imageSrc} alt="Bathing place" className={ss.image} />
      <div className={ss.container}>
        <Typography variant="h4">{props.name}</Typography>
        <Typography variant="body1">{props.description}</Typography>
        <div className={ss.buttons}>
          <Button
            onClick={props.onAddPost}
            variant="contained"
            color="primary"
            fullWidth
          >
            posts
          </Button>
          <Button
            onClick={props.onEditInfo}
            variant="contained"
            color="primary"
            fullWidth
          >
            info
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default PlaceCard;
