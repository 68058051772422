import React, { useContext } from "react";

//CSS
import ss from "./ProtectedNavLink.module.css";

//Components
import IconSelector from "../IconSelector/IconSelector";
import { NavLink } from "react-router-dom";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import AuthContext from "../../../../../../context/auth/auth-context";
import {
  hasPermission,
  isPublic,
} from "../../../../../../models/user-access/UserAccess";

const ProtectedNavLink = (props) => {
  const authCtx = useContext(AuthContext);

  if (!authCtx.isLoggedIn) {
    if (!isPublic(props.path)) return null;
  } else {
    if (!hasPermission(props.path, authCtx.user.permissions)) return null;
  }

  return (
    <NavLink
      to={props.path}
      className={ss.links}
      activeClassName={ss.activeLink}
    >
      <ListItem button className={props.nested ? ss.nested : ""}>
        <ListItemIcon style={{ color: "inherit" }}>
          <IconSelector icon={props.icon} />
        </ListItemIcon>
        <ListItemText primary={props.name} />
      </ListItem>
    </NavLink>
  );
};

export default ProtectedNavLink;
