import React from "react";
import { useQuery } from "@apollo/client";
import { QUERY_USER_BY_ID } from "../../../../../../../network/gql/auth-service";
import EditorRowLayout from "./Layout/EditorRowLayout";

const EditorRow = (props) => {
  const { data, error } = useQuery(QUERY_USER_BY_ID, {
    variables: { id: props.userId },
  });

  if (error) return <p>Something went wrong</p>;

  return (
    <EditorRowLayout
      email={data && data.user.email}
      onDelete={props.onDelete}
    />
  );
};

export default EditorRow;
