import { useContext } from "react";
import { useMutation } from "@apollo/client";
import CritialErrorContext from "../context/critial-error/critial-error-context";
import { gqlErrorParser } from "../components/UI/ValidationErrorViewer/ValidationErrorViewer";

const useMangedMutation = (gql, options, defaultError) => {
  const criticalErrorCtx = useContext(CritialErrorContext);
  const [mutate, { error, loading, data }] = useMutation(gql, options);

  const serverErrors = error && gqlErrorParser(error.graphQLErrors);

  const onMutate = async (subOptions) => {
    try {
      await mutate(subOptions);
    } catch (error) {
      if (error.networkError) {
        criticalErrorCtx.setError(error.networkError, defaultError);
      }
      throw error;
    }
  };

  return [onMutate, { error, loading, data, serverErrors }];
};

export default useMangedMutation;
