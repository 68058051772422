import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { QUERY_PLACES_LIGHT } from "../../../../network/gql/mobile-app-service";
import PageView from "../../../Base/Frames/PageView/PageView";
import GQLQueryErrorHandler from "../../../UI/GQLQueryErrorHandler/GQLQueryErrorHandler";
import CardPlacesList from "./CardPlacesList/CardPlacesList";
import SearchBar from "../../../UI/SearchBar/SearchBar";

const searchFilter = (data, searchFilter) => {
  if (!data) return null;
  if (!searchFilter || searchFilter === "") return data;
  const filter = searchFilter.toUpperCase();
  return data.filter((p) => {
    if (p.name.toUpperCase().indexOf(filter) > -1) return true;
    if (p.description && p.description.toUpperCase().indexOf(filter) > -1)
      return true;
    if (p.owner && p.owner.toUpperCase().indexOf(filter) > -1) return true;
    return false;
  });
};

const PlacesIframeContainer = () => {
  const { data, loading, error } = useQuery(QUERY_PLACES_LIGHT);
  const [searchText, setSearchText] = useState("");

  const onSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const onSearchClear = () => {
    setSearchText("");
  };

  const filteredPlaces = data ? searchFilter(data.places, searchText) : null;

  return (
    <PageView>
      <GQLQueryErrorHandler error={error} />
      <SearchBar
        value={searchText}
        onChange={onSearchChange}
        onClear={onSearchClear}
        disabled={data === null}
        color="secondary"
      />
      {error && <p>An error occured, please check the console.</p>}
      {loading && <p>Loading...</p>}
      {data && <CardPlacesList places={filteredPlaces} />}
    </PageView>
  );
};

export default PlacesIframeContainer;
