import React from "react";

import ss from "./Header.module.css";

import { Typography } from "@material-ui/core";
import ToolTipIconButton from "../../../../../../UI/ToolTipIconButton/ToolTipIconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

const Header = (props) => {
  return (
    <div className={ss.container}>
      <Typography variant="h6">Version: {props.version}</Typography>
      <ToolTipIconButton title="Delete version" onClick={props.onDelete}>
        <DeleteOutlineIcon color="primary" />
      </ToolTipIconButton>
    </div>
  );
};

export default Header;
