import React from "react";
import ImageCard from "./Layout/ImageCard";
import useMangedMutation from "../../../../../../hooks/useManagedMutation";
import {
  MUTATION_DELETE_PLACE_IMAGE,
  MUTATION_SET_IMAGE_PRIMARY,
  QUERY_PLACE_IMAGES,
} from "../../../../../../network/gql/mobile-app-service";
import { useParams } from "react-router";

const ImageCardContainer = (props) => {
  const { placeId } = useParams();
  const [setImagePrimary] = useMangedMutation(MUTATION_SET_IMAGE_PRIMARY, {
    refetchQueries: [
      { query: QUERY_PLACE_IMAGES, variables: { placeId: placeId } },
    ],
  });

  const [deleteImage] = useMangedMutation(MUTATION_DELETE_PLACE_IMAGE, {
    refetchQueries: [
      { query: QUERY_PLACE_IMAGES, variables: { placeId: placeId } },
    ],
  });

  const onSetImagePrimary = async () => {
    try {
      await setImagePrimary({ variables: { id: props.id } });
    } catch (error) {
      console.log(error);
    }
  };

  const onDeleteImage = async () => {
    try {
      await deleteImage({ variables: { id: props.id } });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ImageCard
      path={props.path}
      primary={props.primary}
      onSetImagePrimary={onSetImagePrimary}
      onDeleteImage={onDeleteImage}
    />
  );
};

export default ImageCardContainer;
