import React from "react";

//CSS
import ss from "./PageView.module.css";

const PageView = (props) => {
  return <div className={ss.content}>{props.children}</div>;
};

export default PageView;
