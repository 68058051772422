import React, { useContext } from "react";
import { gql, useMutation } from "@apollo/client";
import CritialErrorContext from "../../../../../context/critial-error/critial-error-context";
import { gqlErrorParser } from "../../../../UI/ValidationErrorViewer/ValidationErrorViewer";
import { useHistory } from "react-router";

//Components
import Section from "../../../../UI/Section/Section";
import DeleteUserForm from "./DeleteUserForm/DeleteUserForm";
import { ALL_USERS_QUERY } from "../../UsersPage/UsersList/UserList";

const DeleteUser = (props) => {
  const criticalErrorCtx = useContext(CritialErrorContext);
  const history = useHistory();
  const [deleteUser, { error }] = useMutation(DELETE_USER, {
    update: (store, { data }) => {
      console.log(data);
      try {
        const { users } = store.readQuery({ query: ALL_USERS_QUERY });
        const newUsers = users.filter((u) => u.id !== data.id);
        store.writeQuery({
          query: ALL_USERS_QUERY,
          data: {
            users: newUsers,
          },
        });
      } catch (error) {
        return;
      }
    },
  });

  const onDeleteUser = async (values, actions) => {
    try {
      await deleteUser({
        variables: { email: values.email, id: props.user.id },
      });
      actions.setSubmitting(false);
      history.replace("/users");
    } catch (error) {
      if (error.networkError) {
        criticalErrorCtx.setError(error.networkError, "Unable to edit user");
      }
      actions.setSubmitting(false);
    }
  };

  return (
    <Section title="Delete User">
      <DeleteUserForm
        initialValues={{ email: "" }}
        onSubmit={onDeleteUser}
        serverErrors={error && gqlErrorParser(error.graphQLErrors)}
      />
    </Section>
  );
};

export default DeleteUser;

const DELETE_USER = gql`
  mutation deleteUser($id: String!, $email: EmailAddress!) {
    id: deleteUser(id: $id, email: $email)
  }
`;
