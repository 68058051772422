import React from "react";
import Section from "../../../../UI/Section/Section";
import FlagInputForm, {
  getInitialValues,
} from "../../Layouts/FlagInputForm/FlagInputForm";
import { useQuery } from "@apollo/client";
import GQLQueryErrorHandler from "../../../../UI/GQLQueryErrorHandler/GQLQueryErrorHandler";
import {
  MUTATION_DELETE_FLAG,
  MUTATION_UPSERT_FLAG,
  QUERY_FLAGS,
} from "../../../../../network/gql/mobile-app-service";
import useMangedMutation from "../../../../../hooks/useManagedMutation";

const initValues = getInitialValues();

const getFlagId = (data, flag) => {
  if (!data) return null;
  const f = data.flags.find((f) => f.flag === flag);
  if (!f) return null;
  return f.id;
};

const getFlagInitValues = (data, flag) => {
  if (!data) return initValues;
  const f = data.flags.find((f) => f.flag === flag);
  if (!f) return initValues;
  return {
    show: !f.disabled,
    seasonFrom: f.seasonFrom,
    seasonTo: f.seasonTo,
    description: f.description,
  };
};

const getFlagDisabled = (data, flag) => {
  if (!data) return false;
  const f = data.flags.find((f) => f.flag === flag);
  if (!f) return false;
  return f.controlledByService;
};

const getErrors = (err1, err2) => {
  if (err1) return err1;
  if (err2) return err2;
  return [];
};

const FlagsForm = (props) => {
  const { data, error, loading, refetch } = useQuery(QUERY_FLAGS, {
    variables: { placeId: props.placeId },
  });
  const [upsertFlag, { serverErrors }] =
    useMangedMutation(MUTATION_UPSERT_FLAG);
  const [deleteFlag, { serverErrors: deleteErrors }] =
    useMangedMutation(MUTATION_DELETE_FLAG);

  const onSubmit = async (id, flagName, values, actions) => {
    const vars = {
      flag: {
        flag: flagName,
        description: values.description,
        seasonFrom: values.seasonFrom,
        seasonTo: values.seasonTo,
        disabled: !values.show,
      },
      flagId: id,
      placeId: props.placeId,
    };

    await upsertFlag({
      variables: vars,
    });
    await refetch();

    actions.setSubmitting(false);
  };

  const onClear = async (id) => {
    if (!window.confirm("Are you sure you want to clear data?")) return;
    await deleteFlag({
      variables: {
        flagId: id,
        placeId: props.placeId,
      },
    });
    refetch();
  };

  return (
    <Section title="Flags">
      {loading && <p>Loading...</p>}
      <GQLQueryErrorHandler error={error} />
      <FlagInputForm
        title="Blue Flag"
        initialValues={getFlagInitValues(data, "BLUE_FLAG")}
        onSubmit={onSubmit.bind(
          null,
          getFlagId(data, "BLUE_FLAG"),
          "BLUE_FLAG"
        )}
        onClear={onClear.bind(null, getFlagId(data, "BLUE_FLAG"))}
        serverErrors={getErrors(serverErrors, deleteErrors)}
        disabled={getFlagDisabled(data, "BLUE_FLAG")}
        deleteDisabled={getFlagId(data, "BLUE_FLAG") === null}
      />
      <FlagInputForm
        title="Badepunkt Flag"
        initialValues={getFlagInitValues(data, "BADEPUNKT_FLAG")}
        onSubmit={onSubmit.bind(
          null,
          getFlagId(data, "BADEPUNKT_FLAG"),
          "BADEPUNKT_FLAG"
        )}
        onClear={onClear.bind(null, getFlagId(data, "BADEPUNKT_FLAG"))}
        serverErrors={getErrors(serverErrors, deleteErrors)}
        disabled={getFlagDisabled(data, "BADEPUNKT_FLAG")}
        deleteDisabled={getFlagId(data, "BADEPUNKT_FLAG") === null}
      />
    </Section>
  );
};

export default FlagsForm;
