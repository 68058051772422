import React from "react";
import { useQuery } from "@apollo/client";
import {
  MUTATION_UPDATE_UNIT,
  QUERY_UNIT,
} from "../../../../../network/gql/system-monitor";
import useMangedMutation from "../../../../../hooks/useManagedMutation";
import { useHistory } from "react-router";
import * as _ from "lodash";
//Components
import Section from "../../../../UI/Section/Section";
import GQLQueryErrorHandler from "../../../../UI/GQLQueryErrorHandler/GQLQueryErrorHandler";
import UnitForm from "../../UnitForm/UnitForm";

const EditUnitContainer = (props) => {
  const history = useHistory();
  const { data, loading, error } = useQuery(QUERY_UNIT, {
    variables: { id: props.id },
  });
  const [updateUnit, { serverErrors }] =
    useMangedMutation(MUTATION_UPDATE_UNIT);

  const onUpdate = async (values, actions) => {
    try {
      await updateUnit({
        variables: { unit: _.omit(values, ["__typename", "id"]), id: props.id },
      });
      actions.setSubmitting(false);
      history.replace("/system-monitor");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Section title="Edit Unit">
      <GQLQueryErrorHandler error={error} />
      {loading && <p>Loading...</p>}
      {data && (
        <UnitForm
          onSubmit={onUpdate}
          initialValues={data.unit}
          serverErrors={serverErrors ? serverErrors : []}
          label="UPDATE"
        />
      )}
    </Section>
  );
};

export default EditUnitContainer;
