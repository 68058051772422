import React from "react";

//CSS
import ss from "./HyperlinkLayout.module.css";

//Components
import ValidatedTextField from "../../ValidatedTextField/ValidatedTextField";
import { IconButton } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const HyperlinkLayout = (props) => {
  return (
    <div className={ss.container}>
      <div className={ss.textContainer}>
        <ValidatedTextField
          name={`${props.arrayName}.${props.index}.text`}
          type="input"
          label="Text"
          fullWidth
        />
        <ValidatedTextField
          name={`${props.arrayName}.${props.index}.url`}
          type="input"
          label="URL"
          fullWidth
        />
      </div>
      <IconButton className={ss.btn} onClick={props.onDelete}>
        <HighlightOffIcon />
      </IconButton>
    </div>
  );
};

export default HyperlinkLayout;
