import React, { useState, useEffect } from "react";

//CSS
import ss from "./DataPoint.module.css";
import clsx from "clsx";

//Components
import { MenuItem, IconButton, Typography } from "@material-ui/core";
import ValidatedTextField from "../../ValidatedTextField/ValidatedTextField";
import ValidatedSelect from "../../ValidatedSelect/ValidatedSelect";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
//import ToolTipIconButton from "../../../../UI/ToolTipIconButton/ToolTipIconButton";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RemoveIcon from "@material-ui/icons/Remove";
import { Fragment } from "react";

const getOpenIcon = (open, hasError) => {
  if (hasError) return <RemoveIcon />;
  if (open)
    return (
      <Fragment>
        <ExpandLessIcon />
        <Typography>LESS</Typography>
      </Fragment>
    );
  return (
    <Fragment>
      <ExpandMoreIcon />
      <Typography>ADVANCE</Typography>
    </Fragment>
  );
};

// const getTextForIcon = (open, hasError) => {
//   if (hasError) return "";
//   if (open) return "Less options";
//   return "More options";
// };

const DataPoint = ({ hasError, ...props }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (hasError) {
      setOpen(true);
    }
  }, [hasError]);

  const onToggleOpen = () => {
    setOpen((state) => !state);
  };

  return (
    <div className={ss.container}>
      <div className={ss.labelBox}>
        <ValidatedTextField
          name={`${props.arrayName}.${props.index}.label`}
          type="input"
          label="Water quality variable name"
          fullWidth
        />
      </div>
      <div className={ss.unitBox}>
        <ValidatedSelect
          label="Unit"
          name={`${props.arrayName}.${props.index}.unit`}
          type="select"
          className={ss.select}
        >
          <MenuItem value="m">m</MenuItem>
          <MenuItem value="pH">pH</MenuItem>
          <MenuItem value="°C">°C</MenuItem>
          <MenuItem value="cfu/100 ml">cfu/100 ml</MenuItem>
          <MenuItem value="MPN/100 ml">MPN/100 ml</MenuItem>
        </ValidatedSelect>
      </div>
      <div className={ss.valueBox}>
        <ValidatedTextField
          name={`${props.arrayName}.${props.index}.value`}
          type="number"
          label="Value"
          fullWidth
        />
      </div>
      <div className={ss.qualityBox}>
        <ValidatedSelect
          label="Quality"
          name={`${props.arrayName}.${props.index}.quality`}
          type="select"
          className={ss.select}
        >
          <MenuItem value="EXCELLENT">Excellent</MenuItem>
          <MenuItem value="GOOD">Good</MenuItem>
          <MenuItem value="MEDIUM">Fair</MenuItem>
          <MenuItem value="BAD">Bad</MenuItem>
        </ValidatedSelect>
      </div>
      <div className={clsx({ [ss.rangeMinBox]: true, [ss.hide]: !open })}>
        <ValidatedTextField
          name={`${props.arrayName}.${props.index}.rangeMin`}
          type="number"
          label="Range Min"
          fullWidth
        />
      </div>
      <div className={clsx({ [ss.rangeMaxBox]: true, [ss.hide]: !open })}>
        <ValidatedTextField
          name={`${props.arrayName}.${props.index}.rangeMax`}
          type="number"
          label="Range Max"
          fullWidth
        />
      </div>
      <div className={clsx({ [ss.limitMinBox]: true, [ss.hide]: !open })}>
        <ValidatedTextField
          name={`${props.arrayName}.${props.index}.limitMin`}
          type="number"
          label="Limit Min (optional)"
          fullWidth
        />
      </div>
      <div className={clsx({ [ss.limitMaxBox]: true, [ss.hide]: !open })}>
        <ValidatedTextField
          name={`${props.arrayName}.${props.index}.limitMax`}
          type="number"
          label="Limit Max (optional)"
          fullWidth
        />
      </div>
      {/* <div className={ss.openButton}>
        <ToolTipIconButton
          title={getTextForIcon(open, hasError)}
          onClick={onToggleOpen}
          disabled={hasError}
        >
          {getOpenIcon(open, hasError)}
        </ToolTipIconButton>
      </div> */}
      <div className={ss.openButton}>
        <IconButton onClick={onToggleOpen} disabled={hasError}>
          {getOpenIcon(open, hasError)}
        </IconButton>
      </div>
      <div className={ss.button}>
        <IconButton className={ss.btn} onClick={props.onDelete}>
          <HighlightOffIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default DataPoint;
