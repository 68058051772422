import React from "react";
import {
  denormalize,
  normalize,
} from "../../../../../models/mobile-app/detailed-info-page-formatter";
import useManagedMutation from "../../../../../hooks/useManagedMutation";
import { useQuery } from "@apollo/client";

//Components
import Section from "../../../../UI/Section/Section";
import DetailedInfoFormLayout from "./Layout/DetailedInfoFormLayout";
import {
  MUTATION_UPSERT_INFO_ELEMENTS,
  QUERY_INFO_ELEMENTS,
} from "../../../../../network/gql/mobile-app-service";
import GQLQueryErrorHandler from "../../../../UI/GQLQueryErrorHandler/GQLQueryErrorHandler";
const DetailedInfoForm = (props) => {
  const {
    data: infoElements,
    error,
    loading,
    refetch,
  } = useQuery(QUERY_INFO_ELEMENTS, {
    variables: { placeId: props.placeId, language: "DA" },
    fetchPolicy: "network-only",
  });
  const [upsertInfoElements, { serverErrors }] = useManagedMutation(
    MUTATION_UPSERT_INFO_ELEMENTS
  );

  const onSubmit = async (values, actions) => {
    try {
      const denom = denormalize(values);
      await upsertInfoElements({
        variables: {
          placeId: props.placeId,
          language: "DA",
          infoElements: denom,
        },
      });
      refetch();
    } catch (error) {
      console.log(error);
    }
    actions.setSubmitting(false);
  };

  const initVals =
    infoElements && infoElements.infoElements
      ? normalize(infoElements.infoElements.elements)
      : null;

  return (
    <Section title="Detailed Info">
      {loading && <p>Loading...</p>}
      <GQLQueryErrorHandler error={error} />
      <DetailedInfoFormLayout
        initialValues={initVals}
        onSubmit={onSubmit}
        serverErrors={serverErrors ? serverErrors : []}
      />
    </Section>
  );
};

export default DetailedInfoForm;
