import React from "react";

//Components
import HeaderDrawerFrame from "../../../Base/Frames/HeaderDrawerFrame/HeaderDrawerFrame";
import PageView from "../../../Base/Frames/PageView/PageView";
import CreateUnitContainer from "./CreateUnit/CreateUnitContainer";
import UnitsListContainer from "./UnitsList/UnitsListContainer";

const UnitsPage = () => {
  return (
    <HeaderDrawerFrame persistent={true}>
      <PageView>
        <CreateUnitContainer />
        <UnitsListContainer />
      </PageView>
    </HeaderDrawerFrame>
  );
};

export default UnitsPage;
