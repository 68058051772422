import React, { Fragment } from "react";
import { Formik } from "formik";
import * as Yup from "yup";

//css
import ss from "./AddEditorForm.module.css";

//Components
import ValidationErrorViewer from "../../../../../../UI/ValidationErrorViewer/ValidationErrorViewer";
import ValidatedTextField from "../../../../../../UI/Form/ValidatedTextField/ValidatedTextField";
import { Button } from "@material-ui/core";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Not a valid email"),
});

const AddEditorForm = (props) => {
  return (
    <Formik
      onSubmit={props.onSubmit}
      initialValues={{ email: "" }}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <Fragment>
          <ValidationErrorViewer messages={props.serverErrors} />
          <form onSubmit={handleSubmit} className={ss.form}>
            <ValidatedTextField
              type="email"
              name="email"
              label="User email"
              fullWidth
              className={ss.input}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isSubmitting || values.email.length === 0}
            >
              Add
            </Button>
          </form>
        </Fragment>
      )}
    </Formik>
  );
};

export default AddEditorForm;
