import React from "react";

//CSS
import ss from "./Header.module.css";
import { makeStyles } from "@material-ui/core/styles";

//Components
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import LoginLogoutButton from "./LoginLogoutButton/LoginLogoutButton";
import Logo from "../../../../assets/ASAP_logo.svg";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const Header = (props) => {
  const classes = useStyles();
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={ss.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={props.toggleDrawer}
        >
          {props.drawerOpen ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
        <div className={ss.titleContainer}>
          <img src={Logo} alt="Logo" className={ss.image} />
          <Typography variant="h6">ASAP platform</Typography>
        </div>
        <LoginLogoutButton />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
