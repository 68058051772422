import React from "react";
//css
import ss from "./UnitCard.module.css";

//Components
import Card from "../../../../../../UI/Card/Card";
import { Typography } from "@material-ui/core";
import Row from "./Row/Row";
import ToolTipIconButton from "../../../../../../UI/ToolTipIconButton/ToolTipIconButton";
import ListIcon from "@material-ui/icons/List";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import clsx from "clsx";
import ValidationErrorViewer from "../../../../../../UI/ValidationErrorViewer/ValidationErrorViewer";
import StateLogListContainer from "./StateLogList/StateLogListContainer";

const UnitCard = (props) => {
  return (
    <Card
      className={clsx({
        [ss.card]: true,
        [ss.warning]: props.state === "WARNING",
        [ss.error]: props.state === "ERROR",
      })}
    >
      <ValidationErrorViewer messages={props.serverErrors} />
      <Typography variant="h5" className={ss.title}>
        {props.name}
      </Typography>
      <div>
        <Row name="Last seen" value={props.lastSeen} unit="minutes ago" />
        <Row name="State" value={props.state} unit="" />
        <Row name="Next scan in" value={props.nextScan} unit="minutes" />
        <Row name="Last scan" value={props.lastScan} unit="minutes ago" />
        <Row name="Interval" value={props.interval} unit="minutes" />
        <Row name="Delay" value={props.delay} unit="minutes" />
        <Row
          name="Max time offline"
          value={props.maxTimeOffline}
          unit="minutes"
        />
      </div>
      <div className={ss.actions}>
        <ToolTipIconButton title="See logs" onClick={props.onToggleShowLog}>
          {props.showLog ? (
            <CloseIcon color="secondary" />
          ) : (
            <ListIcon color="secondary" />
          )}
        </ToolTipIconButton>
        <ToolTipIconButton title="Edit" onClick={props.onEdit}>
          <EditIcon color="secondary" />
        </ToolTipIconButton>
        <ToolTipIconButton title="Delete" onClick={props.onDelete}>
          <DeleteForeverIcon color="secondary" />
        </ToolTipIconButton>
      </div>
      {props.showLog ? <StateLogListContainer id={props.id} /> : null}
    </Card>
  );
};

export default UnitCard;
