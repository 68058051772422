import { ListItemIcon, ListItemText } from "@material-ui/core";
import React from "react";
import Icons from "../../../Icons/Icons";

const MenuItemWithIcon = (props) => {
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <ListItemIcon>
        <Icons type={props.iconType} />
      </ListItemIcon>
      <ListItemText primary={props.text} />
    </div>
  );
};

export default MenuItemWithIcon;
