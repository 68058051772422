import React, { Fragment } from "react";
import { Formik } from "formik";
import * as Yup from "yup";

//CSS
import ss from "./NewPasswordForm.module.css";

//Components
import { Button } from "@material-ui/core";
import ValidatedTextField from "../../../../../UI/Form/ValidatedTextField/ValidatedTextField";
import ValidationErrorViewer from "../../../../../UI/ValidationErrorViewer/ValidationErrorViewer";

const validationSchema = Yup.object().shape({
  password1: Yup.string().required("Required"),
  password2: Yup.string()
    .oneOf([Yup.ref("password1"), null], "Passwords don't match!")
    .required("Required"),
});

const NewPasswordForm = (props) => {
  return (
    <Formik
      initialValues={props.initialValues}
      onSubmit={props.onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Fragment>
          <ValidationErrorViewer messages={props.serverErrors} />
          <form onSubmit={handleSubmit} className={ss.form}>
            <ValidatedTextField
              type="password"
              name="password1"
              label="Password"
              fullWidth
              className={ss.input}
            />
            <ValidatedTextField
              type="password"
              name="password2"
              label="Password again"
              fullWidth
              className={ss.input}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              Create
            </Button>
          </form>
        </Fragment>
      )}
    </Formik>
  );
};

export default NewPasswordForm;
