import React, { Fragment } from "react";
import { useParams } from "react-router";
import { gql, useQuery } from "@apollo/client";

//Compoents
import HeaderDrawerFrame from "../../../Base/Frames/HeaderDrawerFrame/HeaderDrawerFrame";
import PageView from "../../../Base/Frames/PageView/PageView";
import GQLQueryErrorHandler from "../../../UI/GQLQueryErrorHandler/GQLQueryErrorHandler";
import EditUser from "./EditUser/EditUser";
import NewPassword from "./SetNewPassword/NewPassword";
import DeleteUser from "./DeleteUser/DeleteUser";
import NavigationControls from "../../../Base/Frames/NavigationControls/NavigationControls";

const UserPage = (props) => {
  const { id } = useParams();

  const {
    data: userData,
    loading,
    error: queryUserError,
  } = useQuery(USER_PROFILE, {
    variables: { id },
  });

  let sections = null;
  if (userData) {
    sections = (
      <Fragment>
        <NavigationControls />
        <EditUser user={userData.user} />
        <NewPassword user={userData.user} />
        <DeleteUser user={userData.user} />
      </Fragment>
    );
  }

  return (
    <HeaderDrawerFrame persistent={true}>
      <PageView>
        <GQLQueryErrorHandler error={queryUserError} />
        {loading && <p>Loading...</p>}
        {sections}
      </PageView>
    </HeaderDrawerFrame>
  );
};

export default UserPage;

const USER_PROFILE = gql`
  query user($id: String!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      permissions
    }
  }
`;
