import React from "react";
import HeaderDrawerFrame from "../../Base/Frames/HeaderDrawerFrame/HeaderDrawerFrame";
import PageView from "../../Base/Frames/PageView/PageView";
import Section from "../../UI/Section/Section";
import ChangePasswordContainer from "./ChangePassword/ChangePasswordContainer";

const ProfilePage = () => {
  return (
    <HeaderDrawerFrame persistent={true}>
      <PageView>
        <Section title="Change password">
          <ChangePasswordContainer />
        </Section>
      </PageView>
    </HeaderDrawerFrame>
  );
};

export default ProfilePage;
