import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { BrowserRouter } from "react-router-dom";
import PageRouter from "../../PageRouter/PageRouter";
import { ApolloProvider } from "@apollo/client";
import { client } from "../../../network/ApolloClient/ApolloClient";
import { AuthContextProvider } from "../../../context/auth/auth-context";
import { CritialErrorContextProvider } from "../../../context/critial-error/critial-error-context";
import CriticalServerError from "../../UI/CriticalServerError/CriticalServerError";
import { DrawerContextProvider } from "../../../context/drawer/drawer-context";
import CustomTheme from "./CustomTheme/CustomTheme";

function App() {
  return (
    <BrowserRouter>
      <ApolloProvider client={client}>
        <AuthContextProvider>
          <DrawerContextProvider>
            <CustomTheme>
              <CritialErrorContextProvider>
                <CssBaseline />
                <PageRouter />
                <CriticalServerError />
              </CritialErrorContextProvider>
            </CustomTheme>
          </DrawerContextProvider>
        </AuthContextProvider>
      </ApolloProvider>
    </BrowserRouter>
  );
}

export default App;
