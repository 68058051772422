import React from "react";
import { Typography } from "@material-ui/core";
import ToolTipIconButton from "../../../../../../../UI/ToolTipIconButton/ToolTipIconButton";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
//Css
import ss from "./Editors.module.css";

const EditorRowLayout = ({ email, onDelete }) => {
  return (
    <div className={ss.row}>
      <Typography>{email ? email : "Email..."}</Typography>
      <ToolTipIconButton title="Delete" onClick={onDelete}>
        <RemoveCircleOutlineIcon color="error" />
      </ToolTipIconButton>
    </div>
  );
};

export default EditorRowLayout;
