import React, { useEffect } from "react";
import { useLazyQuery, NetworkStatus } from "@apollo/client";
import { QUERY_UNITS } from "../../../../../network/gql/system-monitor";
import GQLQueryErrorHandler from "../../../../UI/GQLQueryErrorHandler/GQLQueryErrorHandler";
import * as _ from "lodash";

//Component
import UnitsList from "./Layout/UnitsList/UnitsList";
import UnitCardContainer from "./UnitCardContainer";

const UnitsListContainer = (props) => {
  const [fetchUnits, { data, loading, error, refetch, networkStatus }] =
    useLazyQuery(QUERY_UNITS, {
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    fetchUnits();
  }, [fetchUnits]);

  const units = data ? _.orderBy(data.units, "info", "asc") : null;
  return (
    <UnitsList
      reload={() => {
        refetch();
      }}
    >
      <GQLQueryErrorHandler error={error} />
      {(loading || networkStatus === NetworkStatus.refetch) && (
        <p>Loading...</p>
      )}
      {units &&
        units.map((unit) => <UnitCardContainer unit={unit} key={unit.id} />)}
    </UnitsList>
  );
};

export default UnitsListContainer;
