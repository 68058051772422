import React, { Fragment } from "react";

//Components
import { Formik } from "formik";
import WarningButton from "../../../../../../UI/WarningButton/WarningButton";
import CheckBox from "../../../../../../UI/Form/CheckBox/CheckBox";
import ValidationErrorViewer from "../../../../../../UI/ValidationErrorViewer/ValidationErrorViewer";

const DeletePostForm = (props) => {
  return (
    <Formik
      initialValues={{
        accept: false,
      }}
      onSubmit={props.onDelete}
    >
      {({ values, handleSubmit, isSubmitting }) => (
        <Fragment>
          <ValidationErrorViewer messages={props.serverErrors} />
          <form onSubmit={handleSubmit}>
            <CheckBox
              type="checkbox"
              name="accept"
              label="I'm sure i want to delete this post! "
              color="primary"
            />
            <WarningButton
              type="submit"
              fullWidth
              variant="contained"
              disabled={!values.accept || isSubmitting}
            >
              DELETE
            </WarningButton>
          </form>
        </Fragment>
      )}
    </Formik>
  );
};

export default DeletePostForm;
