import React, { useContext } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import CritialErrorContext from "../../../../../context/critial-error/critial-error-context";
import { gqlErrorParser } from "../../../../UI/ValidationErrorViewer/ValidationErrorViewer";

//components
import EditUserForm from "./EditUserForm/EditUserForm";
import Section from "../../../../UI/Section/Section";

const EditUser = (props) => {
  const { data: permissionData } = useQuery(ALL_PERMISSIONS);
  const criticalErrorCtx = useContext(CritialErrorContext);
  const [editUser, { error: editUserError }] = useMutation(
    EDIT_USER_WITH_PERMISSION
  );

  const onEditUserSubmit = async (values, actions) => {
    try {
      await editUser({ variables: { ...values, id: props.user.id } });
      actions.setSubmitting(false);
    } catch (error) {
      if (error.networkError) {
        criticalErrorCtx.setError(error.networkError, "Unable to edit user");
      }
      actions.setSubmitting(false);
    }
  };

  //Format permissionsItem
  let permissionItems = [];
  if (permissionData) {
    permissionItems = permissionData.permissions.map((p) => ({
      value: p.role,
      label: p.role,
      description: p.description,
    }));
  }

  const { firstName, lastName, email, permissions } = props.user;
  return (
    <Section title="Edit User">
      <EditUserForm
        initialValues={{
          firstName: firstName,
          lastName: lastName,
          email: email,
          permissions: permissions,
        }}
        serverErrors={
          editUserError && gqlErrorParser(editUserError.graphQLErrors)
        }
        permissionList={permissionItems}
        onSubmit={onEditUserSubmit}
      />
    </Section>
  );
};

export default EditUser;

const ALL_PERMISSIONS = gql`
  query allPermissions {
    permissions {
      role
      description
    }
  }
`;

const EDIT_USER_WITH_PERMISSION = gql`
  mutation updateUserWithPermissions(
    $id: String!
    $firstName: String
    $lastName: String
    $email: EmailAddress
    $permissions: [PERMISSION!]
  ) {
    updateUserWithPermission(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      permissions: $permissions
    ) {
      id
      firstName
      lastName
      email
      permissions
    }
  }
`;
