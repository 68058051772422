import React, { Fragment } from "react";
import { Formik } from "formik";
import * as Yup from "yup";

//css
import ss from "./FilePickerForm.module.css";

//Components
import { Input, Button } from "@material-ui/core";
import ValidationErrorViewer from "../../../../../UI/ValidationErrorViewer/ValidationErrorViewer";

const validationSchema = Yup.object().shape({
  image: Yup.mixed().required(),
});

const FilePickerForm = (props) => {
  return (
    <Formik
      initialValues={{ image: null }}
      validationSchema={validationSchema}
      onSubmit={props.onSubmit}
    >
      {({ handleSubmit, setFieldValue, errors, values }) => (
        <Fragment>
          <ValidationErrorViewer messages={props.serverErrors} />
          <form onSubmit={handleSubmit} className={ss.form}>
            <Input
              inputProps={{
                accept: "image/png, image/jpeg",
              }}
              type="file"
              name="image"
              onChange={(e) => setFieldValue("image", e.currentTarget.files[0])}
              error={errors.image && true}
              className={ss.input}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={!values.image}
            >
              Save
            </Button>
          </form>
        </Fragment>
      )}
    </Formik>
  );
};

export default FilePickerForm;

// <form onSubmit={props.onSubmit}>
//       <label htmlFor="contained-button-file">
//         <Input accept="image/*" id="contained-button-file" type="file" />
//         <Button type="submit">Save</Button>
//       </label>
//     </form>
