import React from "react";
import Section from "../../../../UI/Section/Section";
import VersionForm, {
  getInitialValues,
} from "../Layout/VersionForm/VersionForm";
import useMangedMutation from "../../../../../hooks/useManagedMutation";
import {
  MUTATION_CREATE_APP_VERSION,
  QUERY_APP_VERSIONS,
} from "../../../../../network/gql/mobile-app-service";

const CreateVersionContainer = (props) => {
  const [createAppVersion, { serverErrors }] = useMangedMutation(
    MUTATION_CREATE_APP_VERSION,
    {
      refetchQueries: [
        {
          query: QUERY_APP_VERSIONS,
          variables: { latest: 10 },
        },
      ],
    }
  );

  const onSubmit = async (values, actions) => {
    try {
      await createAppVersion({
        variables: {
          version: {
            ...values,
          },
        },
      });
      actions.setSubmitting(false);
      actions.resetForm();
    } catch (error) {
      console.log(error);
      actions.setSubmitting(false);
    }
  };

  return (
    <Section title="Create Version">
      <VersionForm
        onSubmit={onSubmit}
        initialValues={getInitialValues()}
        serverErrors={serverErrors ? serverErrors : []}
        label="CREATE"
      />
    </Section>
  );
};

export default CreateVersionContainer;
