import React from "react";
import HeaderDrawerFrame from "../../Base/Frames/HeaderDrawerFrame/HeaderDrawerFrame";
import PageView from "../../Base/Frames/PageView/PageView";
import NoAccessMsg from "./Layout/NoAccessMsg";

const NoAccessPageContainer = () => {
  return (
    <HeaderDrawerFrame persistent={true}>
      <PageView>
        <NoAccessMsg />
      </PageView>
    </HeaderDrawerFrame>
  );
};

export default NoAccessPageContainer;
