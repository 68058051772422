import React, { Fragment, useContext } from "react";

//Components
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import IconSelector from "../IconSelector/IconSelector";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
} from "@material-ui/core";
import AuthContext from "../../../../../../context/auth/auth-context";
import {
  hasPermission,
  isPublic,
} from "../../../../../../models/user-access/UserAccess";
import DrawerContext from "../../../../../../context/drawer/drawer-context";

const ProtectedSubList = (props) => {
  const authCtx = useContext(AuthContext);
  const { subListsOpen, toggleSubList } = useContext(DrawerContext);

  const status = props.children.some(({ props }) => {
    const { path } = props;
    if (!path) return false;
    if (!authCtx.isLoggedIn) {
      return isPublic(path);
    } else {
      return hasPermission(path, authCtx.user.permissions);
    }
  });

  if (!status) return null;

  return (
    <Fragment>
      <ListItem button onClick={() => toggleSubList(props.title)}>
        <ListItemIcon style={{ color: "inherit" }}>
          <IconSelector icon={props.titleIcon} />
        </ListItemIcon>
        <ListItemText primary={props.title} />
        {subListsOpen[props.title] ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={subListsOpen[props.title]} timeout="auto">
        <List component="div" disablePadding>
          {props.children}
        </List>
      </Collapse>
    </Fragment>
  );
};

export default ProtectedSubList;
