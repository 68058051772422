import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  from,
  ApolloLink,
  Observable,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { getAccessToken } from "../../context/auth/auth-context";
import { getNewToken } from "./RefreshTokenClient";

/**
 * Middleware
 */

const httpLink = new HttpLink({ uri: process.env.REACT_APP_BACKEND_URL_API });

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: getAccessToken() || null,
    },
  }));
  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      switch (err.extensions.code) {
        case "UNAUTHENTICATED":
          return new Observable((observer) => {
            getNewToken()
              .then((token) => {
                const oldHeaders = operation.getContext().headers;
                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    authorization: token,
                  },
                });
              })
              .then(() => {
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                };

                // Retry last failed request
                forward(operation).subscribe(subscriber);
              })
              .catch((error) => {
                console.log(error);
                observer.error(error);
              });
          });
        default:
      }
    }
  }
});

export const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      User: {
        merge: true,
      },
    },
  }),
  link: from([authMiddleware, errorLink, httpLink]),
});
