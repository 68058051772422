export const normalizePlace = (values) => {
  const vars = {};

  //Create basic
  vars.name = values.name;
  vars.description = values.description;
  vars.lat = values.lat;
  vars.lng = values.lng;
  vars.depth = values.depth;

  //Url
  if (values.url.length > 0) {
    vars.url = values.url;
  } else {
    vars.url = null;
  }

  //Owner
  if (values.owner.length > 0) {
    vars.owner = values.owner;
  } else {
    vars.owner = null;
  }

  //LDDQ
  if (values.LDDQ_enabled) {
    const lddq = {};
    lddq.type = values.LDDQ_type;
    lddq.id = values.LDDQ_id;
    vars.liveDayDataQuery = lddq;
  }

  //FDDQ
  if (values.FDDQ_enabled) {
    const fddq = {};
    fddq.type = values.FDDQ_type;
    fddq.id = values.FDDQ_id;
    fddq.fromDay = values.FDDQ_fromDay;
    fddq.toDay = values.FDDQ_toDay;
    vars.forecastedDayDataQuery = fddq;
  }

  //FTDQ
  if (values.FTDQ_enabled) {
    const ftdq = {};
    ftdq.type = values.FTDQ_type;
    ftdq.id = values.FTDQ_id;
    ftdq.fromDay = values.FTDQ_fromDay;
    ftdq.toDay = values.FTDQ_toDay;
    vars.forecastedTableDataQuery = ftdq;
  }

  return vars;
};

export const denormalizePlace = (place) => {
  const {
    name,
    description,
    url,
    owner,
    lat,
    lng,
    depth,
    liveDayDataQuery,
    forecastedDayDataQuery,
    forecastedTableDataQuery,
  } = place;
  const denom = {
    name,
    description,
    url: url ? url : "",
    owner: owner ? owner : "",
    lat,
    lng,
    depth,
  };

  if (liveDayDataQuery) {
    denom.LDDQ_enabled = true;
    denom.LDDQ_type = liveDayDataQuery.type;
    denom.LDDQ_id = liveDayDataQuery.id;
  } else {
    denom.LDDQ_enabled = false;
    denom.LDDQ_type = "";
    denom.LDDQ_id = "";
  }

  if (forecastedDayDataQuery) {
    denom.FDDQ_enabled = true;
    denom.FDDQ_type = forecastedDayDataQuery.type;
    denom.FDDQ_id = forecastedDayDataQuery.id;
    denom.FDDQ_fromDay = forecastedDayDataQuery.fromDay;
    denom.FDDQ_toDay = forecastedDayDataQuery.toDay;
  } else {
    denom.FDDQ_enabled = false;
    denom.FDDQ_type = "";
    denom.FDDQ_id = "";
    denom.FDDQ_fromDay = 0;
    denom.FDDQ_toDay = 6;
  }

  if (forecastedTableDataQuery) {
    denom.FTDQ_enabled = true;
    denom.FTDQ_type = forecastedTableDataQuery.type;
    denom.FTDQ_id = forecastedTableDataQuery.id;
    denom.FTDQ_fromDay = forecastedTableDataQuery.fromDay;
    denom.FTDQ_toDay = forecastedTableDataQuery.toDay;
  } else {
    denom.FTDQ_enabled = false;
    denom.FTDQ_type = "";
    denom.FTDQ_id = "";
    denom.FTDQ_fromDay = 0;
    denom.FTDQ_toDay = 6;
  }

  return denom;
};
