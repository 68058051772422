import React from "react";
import Body from "./Body/Body";
import Header from "./Header/Header";

//css
import ss from "./VersionRow.module.css";

const VersionRow = (props) => {
  return (
    <div className={ss.container}>
      <Header version={props.version} onDelete={props.onDelete} />
      <Body
        descriptionDA={props.descriptionDA}
        descriptionEN={props.descriptionEN}
        expoUpdate={props.expoUpdate}
        forcedUpdate={props.forcedUpdate}
      />
    </div>
  );
};

export default VersionRow;
