import React, { useContext } from "react";

//Components
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import CritialErrorContext from "../../../context/critial-error/critial-error-context";

const CriticalServerError = (props) => {
  const criticalErrorCtx = useContext(CritialErrorContext);
  return (
    <Dialog
      open={criticalErrorCtx.hasError}
      onClose={criticalErrorCtx.clearError}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">{"Server error"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {criticalErrorCtx.errorMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={criticalErrorCtx.clearError} color="primary" autoFocus>
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CriticalServerError;
