import React, { useContext } from "react";
import AuthContext from "../../../../../context/auth/auth-context";
import { useHistory } from "react-router-dom";

//Components
import LoggedInButton from "./LoggedInButton/LoggedInButton";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

const LoginLogoutButton = (props) => {
  const history = useHistory();
  const authContext = useContext(AuthContext);

  const onLogoutClicked = () => {
    authContext.logout();
    history.push("/home");
  };

  const onProfileClicked = () => {
    history.push("/profile");
  };

  if (!authContext.isLoggedIn) {
    return (
      <Link to="/login" style={{ color: "inherit", textDecoration: "none" }}>
        <Typography variant="h6">Login</Typography>
      </Link>
    );
  }

  return (
    <LoggedInButton
      firstName={authContext.user.firstName}
      lastName={authContext.user.lastName}
      onLogoutClicked={onLogoutClicked}
      onProfileClicked={onProfileClicked}
    />
  );
};

export default LoginLogoutButton;
