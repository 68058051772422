import React from "react";
import { useParams } from "react-router";

//Components
import EditPostFormContainer from "./EditPostForm/EditPostFormContainer";
import HeaderDrawerFrame from "../../../../Base/Frames/HeaderDrawerFrame/HeaderDrawerFrame";
import PageView from "../../../../Base/Frames/PageView/PageView";
import DeletePostContainer from "./DeletePost/DeletePostContainer";
import NavigationControls from "../../../../Base/Frames/NavigationControls/NavigationControls";

const EditPostContainer = (props) => {
  const { placeId, id } = useParams();

  return (
    <HeaderDrawerFrame persistent={true}>
      <PageView>
        <NavigationControls />
        <EditPostFormContainer id={id} placeId={placeId} />
        <DeletePostContainer id={id} placeId={placeId} />
      </PageView>
    </HeaderDrawerFrame>
  );
};

export default EditPostContainer;
