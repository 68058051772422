import React from "react";
import classNames from "classnames";

//Css
import ss from "./ValidationErrorViewer.module.css";

//Componets
import Typography from "@material-ui/core/Typography";

const ValidationErrorViewer = (props) => {
  if (!props.messages || props.messages.length === 0) return null;

  const messages = props.messages.map((msg) => (
    <Typography variant="body2" color="error" key={msg}>
      {msg}
    </Typography>
  ));

  return (
    <div
      className={classNames({
        [ss.container]: true,
        [ss.center]: props.center,
      })}
    >
      {messages}
    </div>
  );
};

export default ValidationErrorViewer;

export const gqlErrorParser = (graphQLErrors) => {
  let errorMessages = [];
  graphQLErrors.forEach((err) => {
    if (err.extensions && err.extensions.validationErrors) {
      errorMessages = errorMessages.concat(
        Object.values(err.extensions.validationErrors)
      );
    } else {
      errorMessages.push(err.message);
    }
  });
  return errorMessages;
};
