import React from "react";

//CSS
import ss from "./ActionsLayout.module.css";

//Components
import { IconButton, Typography } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";

const ActionsLayout = (props) => {
  return (
    <div className={ss.container}>
      {props.children}
      <div className={ss.actions}>
        <IconButton classes={{ label: ss.addAction }} onClick={props.onAdd}>
          <AddCircleIcon color="secondary" />
          <Typography variant="button">{props.label}</Typography>
        </IconButton>
      </div>
    </div>
  );
};

export default ActionsLayout;
