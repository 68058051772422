import React from "react";
import { FieldArray } from "formik";
import { v4 as uuidv4 } from "uuid";

//Components
import ActionsLayout from "../ActionLayout/ActionsLayout";
import StatementLayout from "./Layout/StatementLayout";

const StatementsArray = (props) => {
  return (
    <FieldArray name={props.arrayName}>
      {(helpers) => {
        const onDelete = (index) => {
          helpers.remove(index);
        };

        const onAdd = () => {
          helpers.push(getInitItem());
        };

        const onMoveUp = (currentIndex) => {
          if (
            props.items.length <= 1 ||
            currentIndex === props.items.length - 1
          )
            return;
          helpers.swap(currentIndex, currentIndex + 1);
        };

        const onMoveDown = (currentIndex) => {
          if (props.items.length <= 1 || currentIndex === 0) return;
          helpers.swap(currentIndex, currentIndex - 1);
        };

        return (
          <ActionsLayout onAdd={onAdd} label="Add Statement">
            {props.items.map((st, index) => {
              return (
                <StatementLayout
                  key={st.id}
                  arrayName={props.arrayName}
                  index={index}
                  isFirst={index === 0}
                  isLast={index === props.items.length - 1}
                  label={props.label}
                  onDelete={onDelete.bind(null, index)}
                  onMoveUp={onMoveUp.bind(null, index)}
                  onMoveDown={onMoveDown.bind(null, index)}
                >
                  {props.children}
                </StatementLayout>
              );
            })}
          </ActionsLayout>
        );
      }}
    </FieldArray>
  );
};

export default StatementsArray;

export const getInitItem = () => {
  return { id: uuidv4(), icon: "", text: "" };
};
