import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/client";
import { client } from "../../../../../network/ApolloClient/ApolloClient";

//Components
import Section from "../../../../UI/Section/Section";
import AddEditorForm from "./Layout/AddEditorForm/AddEditorForm";
import { QUERY_USER_ID_BY_EMAIL } from "../../../../../network/gql/auth-service";
import CritialErrorContext from "../../../../../context/critial-error/critial-error-context";
import {
  MUTATION_ADD_EDITOR,
  QUERY_PLACE_EDITORS,
} from "../../../../../network/gql/mobile-app-service";
import { gqlErrorParser } from "../../../../UI/ValidationErrorViewer/ValidationErrorViewer";
import EditorsList from "./Layout/EditorsList/EditorsList";

const EditorsContainer = (props) => {
  const criticalErrorCtx = useContext(CritialErrorContext);
  const [addEditor, { error }] = useMutation(MUTATION_ADD_EDITOR, {
    refetchQueries: [
      { query: QUERY_PLACE_EDITORS, variables: { placeId: props.placeId } },
    ],
  });
  const [emailError, setEmailError] = useState([]);

  const onAddUser = async (values, actions) => {
    try {
      const { data } = await client.query({
        query: QUERY_USER_ID_BY_EMAIL,
        variables: { email: values.email },
      });
      if (!data.userByEmail) {
        setEmailError(["Email does not exist"]);
        return;
      }
      await addEditor({
        variables: { placeId: props.placeId, userId: data.userByEmail.id },
      });
      actions.setSubmitting(false);
      actions.resetForm();
    } catch (error) {
      if (error.networkError) {
        criticalErrorCtx.setError(error.networkError, "Unable to update place");
      }
      actions.setSubmitting(false);
    }
    setEmailError(null);
  };

  let combinedErrors = error ? gqlErrorParser(error.graphQLErrors) : [];
  combinedErrors = emailError
    ? [emailError, ...combinedErrors]
    : combinedErrors;

  return (
    <Section title="Editors">
      <EditorsList placeId={props.placeId} />
      <AddEditorForm onSubmit={onAddUser} serverErrors={combinedErrors} />
    </Section>
  );
};

export default EditorsContainer;
