import { Typography } from "@material-ui/core";
import React from "react";

//CSS
import ss from "./UserRow.module.css";

//Components
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

const UserRow = (props) => {
  return (
    <div className={ss.container}>
      <Typography variant="h6">
        {props.firstName} {props.lastName}
      </Typography>
      <div>
        <IconButton onClick={props.onEdit}>
          <EditIcon color="primary" />
        </IconButton>
      </div>
    </div>
  );
};

export default UserRow;
