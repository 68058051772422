import React, { useContext } from "react";
import AuthContext from "../../../../context/auth/auth-context";
import ChangePasswordForm, {
  getInitValues,
} from "./ChangePasswordForm/ChangePasswordForm";
import { useHistory } from "react-router";
import useMangedMutation from "../../../../hooks/useManagedMutation";
import { MUTATION_USER_CHANGE_PASSWORD } from "../../../../network/gql/auth-service";

const ChangePasswordContainer = (props) => {
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const [changePassword, { serverErrors }] = useMangedMutation(
    MUTATION_USER_CHANGE_PASSWORD
  );

  const onChangePassword = async (values, actions) => {
    try {
      changePassword({ variables: { ...values } });
      actions.resetForm();
      actions.setSubmitting(false);
      await authCtx.logout();
      history.replace("/login");
    } catch (error) {
      actions.setSubmitting(false);
    }
  };

  return (
    <ChangePasswordForm
      initialValues={getInitValues()}
      onSubmit={onChangePassword}
      serverErrors={serverErrors ? serverErrors : []}
    />
  );
};

export default ChangePasswordContainer;
