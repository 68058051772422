import React, { useContext } from "react";
import { useMutation } from "@apollo/client";
import Section from "../../../../UI/Section/Section";
import PlaceConfigForm, {
  getInitValues,
} from "../../Layouts/PlaceConfig/PlaceConfigForm";
import {
  MUTATION_CREATE_PLACE,
  QUERY_ALL_PLACES,
} from "../../../../../network/gql/mobile-app-service";
import { gqlErrorParser } from "../../../../UI/ValidationErrorViewer/ValidationErrorViewer";
import CritialErrorContext from "../../../../../context/critial-error/critial-error-context";
import { normalizePlace } from "../../../../../models/mobile-app/create-place-formatter-model";

const CreatePlaceContainer = () => {
  const criticalErrorCtx = useContext(CritialErrorContext);
  const [createPlace, { error }] = useMutation(MUTATION_CREATE_PLACE, {
    refetchQueries: [{ query: QUERY_ALL_PLACES }],
  });

  const onSubmit = async (values, actions) => {
    try {
      const vars = normalizePlace(values);
      await createPlace({ variables: { InputPlace: vars } });
      actions.setSubmitting(false);
      actions.resetForm();
    } catch (error) {
      if (error.networkError) {
        criticalErrorCtx.setError(error.networkError, "Unable to create palce");
      }
      actions.setSubmitting(false);
    }
  };

  return (
    <Section title="Create Place">
      <PlaceConfigForm
        onSubmit={onSubmit}
        initialValues={getInitValues()}
        serverErrors={error && gqlErrorParser(error.graphQLErrors)}
        label="CREATE"
      />
    </Section>
  );
};

export default CreatePlaceContainer;
