import React, { Fragment } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import * as _ from "lodash";

import ss from "./UnitForm.module.css";

import ValidationErrorViewer from "../../../UI/ValidationErrorViewer/ValidationErrorViewer";
import ValidatedTextField from "../../../UI/Form/ValidatedTextField/ValidatedTextField";
import ValidatedSelect from "../../../UI/Form/ValidatedSelect/ValidatedSelect";
import { Button, MenuItem } from "@material-ui/core";

const validationSchema = Yup.object().shape({
  unitId: Yup.string().required("Required"),
  info: Yup.string(),
  type: Yup.string().required("Required"),
  datasource: Yup.string().required("Required"),
  interval: Yup.number().required("Required").min(0),
  delay: Yup.number().when("type", {
    is: "TIMEBASED",
    then: Yup.number().required("Required").min(0),
  }),
  maxTimeOffline: Yup.number().when("type", {
    is: "TIMEBASED",
    then: Yup.number().required("Required").min(0),
  }),
});

const UnitForm = (props) => {
  return (
    <Formik
      initialValues={props.initialValues}
      onSubmit={props.onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ handleSubmit, values, initialValues, isSubmitting }) => (
        <Fragment>
          <ValidationErrorViewer messages={props.serverErrors} />
          <form className={ss.form} onSubmit={handleSubmit}>
            <ValidatedTextField
              name="unitId"
              type="input"
              label="Unit ID"
              className={ss.input}
              fullWidth
            />
            <ValidatedTextField
              name="info"
              type="input"
              label="Info"
              className={ss.input}
              fullWidth
            />
            <div className={ss.selectContainer}>
              <ValidatedSelect
                label="Type"
                name="type"
                type="select"
                className={ss.select}
              >
                <MenuItem value="TIMEBASED">Timebased</MenuItem>
              </ValidatedSelect>
            </div>
            <div className={ss.selectContainer}>
              <ValidatedSelect
                label="Data source"
                name="datasource"
                type="select"
                className={ss.select}
              >
                <MenuItem value="WATTECO_TEMP_TIME_BASED">
                  Watteco Temp timebased
                </MenuItem>
                <MenuItem value="AQUATROLL_TIME_BASED">
                  Aquatroll timebased
                </MenuItem>
                <MenuItem value="ALMIND_TIME_BASED">Almind timebased</MenuItem>
              </ValidatedSelect>
            </div>
            <ValidatedTextField
              name="interval"
              type="number"
              label="Interval"
              className={ss.input}
              fullWidth
            />
            <ValidatedTextField
              name="delay"
              type="number"
              label="Delay"
              className={ss.input}
              fullWidth
            />
            <ValidatedTextField
              name="maxTimeOffline"
              type="number"
              label="Max time offline"
              className={ss.input}
              fullWidth
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting || _.isEqual(values, initialValues)}
            >
              {props.label}
            </Button>
          </form>
        </Fragment>
      )}
    </Formik>
  );
};

export const getInitValues = () => {
  return {
    unitId: "",
    info: "",
    type: "",
    datasource: "",
    interval: 0,
    delay: "",
    maxTimeOffline: "",
  };
};

export default UnitForm;
