import React from "react";
import clsx from "clsx";

//CSS
import ss from "./Content.module.css";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 250,
  },
}));

const Content = (props) => {
  const classes = useStyles();
  return (
    <main
      className={clsx(classes.content, {
        [classes.contentShift]: props.drawerOpen && props.active,
      })}
    >
      <div className={classes.toolbar} />
      <div className={ss.container}>{props.children}</div>
    </main>
  );
};

export default Content;
