import React from "react";

//CSS
import ss from "./BarGraph.module.css";

//Components
import Headline from "./Headline/Headline";
import Bar from "./Bar/Bar";
import Labels from "./Labels/Labels";

const calcRelatives = (rangeMin, rangeMax, value, lowLimit, highLimit) => {
  const relatives = {};
  const diff = rangeMax - rangeMin;
  relatives.value = ((value - rangeMin) / diff) * 100;

  if (lowLimit !== undefined && lowLimit !== null) {
    relatives.lowLimit = ((lowLimit - rangeMin) / diff) * 100;
  }

  if (highLimit !== undefined && highLimit !== null) {
    relatives.highLimit = ((highLimit - rangeMin) / diff) * 100;
  }

  return relatives;
};

const getColor = (status) => {
  switch (status) {
    case "GOOD":
      return "green";
    case "MEDIUM":
      return "yellow";
    case "BAD":
      return "red";
    default:
      return "grey";
  }
};

const BarGraph = ({
  status,
  rangeMin,
  rangeMax,
  value,
  label,
  unit,
  lowLimit,
  highLimit,
}) => {
  const color = getColor(status);
  const relatives = calcRelatives(
    rangeMin,
    rangeMax,
    value,
    lowLimit,
    highLimit
  );
  return (
    <div className={ss.container}>
      <Headline label={label} value={value} unit={unit} />
      <Bar
        relativeValue={relatives.value}
        color={color}
        lowLimitRelative={relatives.lowLimit}
        highLimitRelative={relatives.highLimit}
      />
      <Labels minRange={rangeMin} maxRange={rangeMax} />
    </div>
  );
};

export default BarGraph;
