import React from "react";
import HeaderDrawerFrame from "../../../Base/Frames/HeaderDrawerFrame/HeaderDrawerFrame";
import PageView from "../../../Base/Frames/PageView/PageView";
import DetailedInfoForm from "./DetailedInfoForm/DetailedInfoForm";
import ImageUploadContainer from "./ImageUpload/ImageUploadContainer";
import { useParams } from "react-router";
import NavigationControls from "../../../Base/Frames/NavigationControls/NavigationControls";
import FlagsForm from "./FlagsForm/FlagsForm";

const InfoPage = (props) => {
  const { placeId } = useParams();

  return (
    <HeaderDrawerFrame persistent={true}>
      <PageView>
        <NavigationControls />
        <ImageUploadContainer placeId={placeId} />
        <FlagsForm placeId={placeId}/>
        <DetailedInfoForm placeId={placeId} />
      </PageView>
    </HeaderDrawerFrame>
  );
};

export default InfoPage;
