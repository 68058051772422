import React, { useContext } from "react";
import Section from "../../../../UI/Section/Section";
import { gql, useMutation, useQuery } from "@apollo/client";
import { gqlErrorParser } from "../../../../UI/ValidationErrorViewer/ValidationErrorViewer";

//Components
import CreateNewUserLayout from "./Layout/CreateNewUserLayout";
import CritialErrorContext from "../../../../../context/critial-error/critial-error-context";
import { ALL_USERS_QUERY } from "../UsersList/UserList";

const CreateNewUser = (props) => {
  const criticalErrorCtx = useContext(CritialErrorContext);
  const [createUser, { error }] = useMutation(CREATE_USER_MUTATION, {
    refetchQueries: [{ query: ALL_USERS_QUERY }],
  });
  const { data } = useQuery(ALL_PERMISSIONS);

  const onCreateNewUser = async (values, actions) => {
    try {
      await createUser({ variables: { ...values } });
      actions.setSubmitting(false);
      actions.resetForm();
    } catch (error) {
      if (error.networkError) {
        criticalErrorCtx.setError(error.networkError, "Unable to create user");
      }
      actions.setSubmitting(false);
    }
  };

  let permissionItems = [];
  if (data) {
    permissionItems = data.permissions.map((p) => ({
      value: p.role,
      label: p.role,
      description: p.description,
    }));
  }
  return (
    <Section title="Create User">
      <CreateNewUserLayout
        serverErrors={error && gqlErrorParser(error.graphQLErrors)}
        onSubmit={onCreateNewUser}
        initialValues={CREATE_USER_FORM_INIT}
        permissionList={permissionItems}
      />
    </Section>
  );
};

export default CreateNewUser;

const ALL_PERMISSIONS = gql`
  query allPermissions {
    permissions {
      role
      description
    }
  }
`;

const CREATE_USER_MUTATION = gql`
  mutation createUser(
    $firstName: String!
    $lastName: String!
    $email: EmailAddress!
    $password1: String!
    $password2: String!
    $permissions: [PERMISSION!]!
  ) {
    userSignUp(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password1: $password1
      password2: $password2
      permissions: $permissions
    ) {
      firstName
      lastName
      email
    }
  }
`;

const CREATE_USER_FORM_INIT = {
  firstName: "",
  lastName: "",
  email: "",
  password1: "",
  password2: "",
  permissions: [],
};
