import React, { Fragment } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import * as _ from "lodash";

//Css
import ss from "./DetailedInfoFormLayout.module.css";

//Components
import ValidatedTextField from "../../../../../UI/Form/ValidatedTextField/ValidatedTextField";
import StatementsArray, {
  getInitItem,
} from "../../../../../UI/Form/StatementsArray/StatementsArray";
import { Button, MenuItem, Typography } from "@material-ui/core";
import HyperlinkArray, {
  getInitItem as getHyperlinkInitItem,
} from "../../../../../UI/Form/HyperlinkArray/HyperlinkArray";
import ValidationErrorViewer from "../../../../../UI/ValidationErrorViewer/ValidationErrorViewer";
import MenuItemWithIcon from "../../../../../UI/Form/StatementsArray/MenuItemWithIcon/MenuItemWithIcon";
import {
  ICON_CHECK,
  ICON_CLOCK,
  ICON_DANGER,
  ICON_INFO,
  ICON_WARNING,
  ICON_PARKING,
  ICON_LOCATION,
  ICON_ACHIEVEMENT,
} from "../../../../../../constants/IconTypes";

const validationSchema = Yup.object().shape({
  headline_1: Yup.string()
    .required("Required")
    .min(4, "Too short")
    .max(50, "Too long"),
  body_1: Yup.string()
    .required("Required")
    .min(20, "Too short")
    .max(200, "Too long"),
  statements: Yup.array().of(
    Yup.object({
      icon: Yup.string().required("Required"),
      text: Yup.string()
        .required("Required")
        .min(5, "Too short")
        .max(70, "Too long"),
    })
  ),
  body_2: Yup.string().min(40, "Too short"),
  hyperlinks: Yup.array().of(
    Yup.object({
      text: Yup.string()
        .required("Required")
        .min(5, "Too short")
        .max(50, "Too long"),
      url: Yup.string().required("Required").url("Must be a url"),
    })
  ),
});

const DetailedInfoFormLayout = (props) => {
  return (
    <Formik
      initialValues={
        props.initialValues ? props.initialValues : getInitialValues()
      }
      onSubmit={props.onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ handleSubmit, isSubmitting, values, initialValues }) => (
        <Fragment>
          <ValidationErrorViewer messages={props.serverErrors} />
          <form onSubmit={handleSubmit} className={ss.form}>
            <ValidatedTextField
              name="headline_1"
              type="input"
              label="Headline"
              className={ss.input}
              fullWidth
            />
            <Typography variant="h6">
              Short site description (20-200 characters)
            </Typography>
            <ValidatedTextField
              name="body_1"
              type="input"
              label="Short intro"
              className={ss.input}
              fullWidth
              rows={8}
              multiline
              variant="outlined"
            />
            <Typography variant="h6">
              Information shown as a bullet list (optional, remove if not
              needed)
            </Typography>
            <StatementsArray
              arrayName="statements"
              items={values.statements}
              label="Information category"
            >
              <MenuItem value={ICON_ACHIEVEMENT}>
                <MenuItemWithIcon
                  iconType={ICON_ACHIEVEMENT}
                  text="Achievement"
                />
              </MenuItem>
              <MenuItem value={ICON_INFO}>
                <MenuItemWithIcon iconType={ICON_INFO} text="Information" />
              </MenuItem>
              <MenuItem value={ICON_CLOCK}>
                <MenuItemWithIcon iconType={ICON_CLOCK} text="Event time" />
              </MenuItem>
              <MenuItem value={ICON_PARKING}>
                <MenuItemWithIcon iconType={ICON_PARKING} text="Parking" />
              </MenuItem>
              <MenuItem value={ICON_LOCATION}>
                <MenuItemWithIcon iconType={ICON_LOCATION} text="Address" />
              </MenuItem>
              <MenuItem value={ICON_CHECK}>
                <MenuItemWithIcon iconType={ICON_CHECK} text="Good" />
              </MenuItem>
              <MenuItem value={ICON_WARNING}>
                <MenuItemWithIcon iconType={ICON_WARNING} text="Warning" />
              </MenuItem>
              <MenuItem value={ICON_DANGER}>
                <MenuItemWithIcon iconType={ICON_DANGER} text="Danger" />
              </MenuItem>
            </StatementsArray>
            <Typography variant="h6">
              Long description about the site (optional)
            </Typography>
            <ValidatedTextField
              name="body_2"
              type="input"
              label="Long description"
              className={ss.input}
              fullWidth
              rows={8}
              multiline
              variant="outlined"
            />
            <Typography variant="h6">
              Hyperlinks to external references (optional, remove if not needed)
            </Typography>
            <HyperlinkArray arrayName="hyperlinks" items={values.hyperlinks} />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting || _.isEqual(values, props.initialValues)}
            >
              Save
            </Button>
          </form>
        </Fragment>
      )}
    </Formik>
  );
};

export default DetailedInfoFormLayout;

export const getInitialValues = () => {
  return {
    headline_1: "",
    body_1: "",
    statements: [getInitItem()],
    body_2: "",
    hyperlinks: [getHyperlinkInitItem()],
  };
};
