import React from "react";
import { useParams } from "react-router";

import HeaderDrawerFrame from "../../../Base/Frames/HeaderDrawerFrame/HeaderDrawerFrame";
import NavigationControls from "../../../Base/Frames/NavigationControls/NavigationControls";
import PageView from "../../../Base/Frames/PageView/PageView";
import ConfigurePlace from "./ConfigurePlace/ConfigurePlaceContainer";
import DeletePlaceContainer from "./DeletePlace/DeletePlaceContainer";
import EditorsContainer from "./Editors/EditorsContainer";

const EditPlacePageContainer = (props) => {
  const { id } = useParams();
  return (
    <HeaderDrawerFrame persistent={true}>
      <PageView>
        <NavigationControls />
        <ConfigurePlace placeId={id} />
        <DeletePlaceContainer placeId={id} />
        <EditorsContainer placeId={id} />
      </PageView>
    </HeaderDrawerFrame>
  );
};

export default EditPlacePageContainer;
