import React, { useState } from "react";
import axios from "axios";
import Section from "../../../../UI/Section/Section";
import FilePickerForm from "./Layout/FilePickerForm";
import { getAccessToken } from "../../../../../context/auth/auth-context";
import { useQuery } from "@apollo/client";
import { QUERY_PLACE_IMAGES } from "../../../../../network/gql/mobile-app-service";
import Gallery from "./Layout/Gallery/Gallery";
import ImageCardContainer from "./ImageCard/ImageCardContainer";

const ImageUploadContainer = (props) => {
  const [error, setError] = useState(null);
  const { data, refetch } = useQuery(QUERY_PLACE_IMAGES, {
    variables: { placeId: props.placeId },
  });
  const onSubmit = async (values, actions) => {
    const formData = new FormData();
    formData.append("image", values.image);
    formData.append("placeId", props.placeId);

    try {
      setError(null);
      await axios.post(
        `${process.env.REACT_APP_BACKEND_IMAGES_UPLOAD}/images/gallery`,
        formData,
        {
          headers: {
            authorization: getAccessToken(),
          },
        }
      );
      refetch();
      actions.setSubmitting(false);
      actions.resetForm();
    } catch (error) {
      if (error.response.data) {
        setError(error.response.data);
      } else {
        console.log(error);
        setError("Unable to uploade image to server");
      }
    }
    actions.setSubmitting(false);
  };

  return (
    <Section title="Image">
      <Gallery>
        {data &&
          data.placeImages.map((img) => (
            <ImageCardContainer
              key={img.id}
              id={img.id}
              path={img.path}
              primary={img.primary}
            />
          ))}
      </Gallery>
      <FilePickerForm onSubmit={onSubmit} serverErrors={error ? [error] : []} />
    </Section>
  );
};

export default ImageUploadContainer;
