import React from "react";
import moment from "moment-timezone";
import { useMediaQuery } from "react-responsive";

//css
import ss from "./TableRow.module.css";

//Components
import { Typography } from "@material-ui/core";
import ToolTipIconButton from "../../../../../../UI/ToolTipIconButton/ToolTipIconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from "@material-ui/icons/FileCopy";

const parseType = (type, smallScreen) => {
  switch (type) {
    case "WATERQUALITY":
      return smallScreen ? "WQ" : "Waterquality";

    default:
      return smallScreen ? "Info" : "Information";
  }
};

const TableRow = (props) => {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 400px)" });
  return (
    <div className={ss.container}>
      <div className={ss.row}>
        <Typography variant="h6">
          {parseType(props.type, isSmallScreen)}{" "}
          {moment(props.date).format("DD. MMMM YYYY")}
        </Typography>
        <div className={ss.actions}>
          <ToolTipIconButton title="View" onClick={props.toggleShow}>
            {props.show ? (
              <VisibilityOffIcon color="secondary" />
            ) : (
              <VisibilityIcon color="secondary" />
            )}
          </ToolTipIconButton>
          <ToolTipIconButton title="Edit post" onClick={props.onEdit}>
            <EditIcon color="secondary" />
          </ToolTipIconButton>
          <ToolTipIconButton title="Use as template" onClick={props.onCopy}>
            <FileCopyIcon color="secondary" />
          </ToolTipIconButton>
        </div>
      </div>
      {props.show && props.children}
    </div>
  );
};

export default TableRow;
