import React, { useState } from "react";

const CritialErrorContext = React.createContext({
  hasError: false,
  errorMessage: null,
  setError: (error, fallback) => {},
  clearError: () => {},
});

export const CritialErrorContextProvider = (props) => {
  const [errorMsg, setErrorMsg] = useState(null);

  const onSetError = (error, fallback) => {
    if (error && error.result && error.result.errors.length > 0) {
      setErrorMsg(error.result.errors[0].message);
    } else if (error && typeof error.message === "string") {
      setErrorMsg(error.message);
    } else if (error && typeof error === "string") {
      setErrorMsg(error);
    } else if (fallback) {
      setErrorMsg(fallback);
    } else {
      setErrorMsg("Server error");
    }
  };

  const onClearError = () => {
    setErrorMsg(null);
  };

  const contextValue = {
    hasError: errorMsg !== null,
    errorMessage: errorMsg,
    setError: onSetError,
    clearError: onClearError,
  };

  return (
    <CritialErrorContext.Provider value={contextValue}>
      {props.children}
    </CritialErrorContext.Provider>
  );
};

export default CritialErrorContext;
