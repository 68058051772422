import React, { useState, useEffect } from "react";
import {
  formatToDb,
  formatToForm,
} from "../../../../../models/mobile-app/post-formatter-model";
import {
  formatToDb as Info_formatToDb,
  formatToForm as Info_formatToForm,
} from "../../../../../models/mobile-app/info-post-formatter-model";
import useMangedMutation from "../../../../../hooks/useManagedMutation";
import {
  MUTATION_CREATE_POST,
  QUERY_POSTS,
} from "../../../../../network/gql/mobile-app-service";
import { WATERQUALITY, INFORMATION } from "../../../../../constants/PostTypes";

//Components
import Section from "../../../../UI/Section/Section";
import PostForm, { getInitialValues } from "../Layout/Post/PostForm";
import InfoPostForm, {
  getInitialValues as Info_getInitialValues,
} from "../Layout/InfoPost/InfoPostForm";
import Controls from "./Layout/Controls/Controls";

const CreatePostContainer = ({ copiedPost, ...props }) => {
  const [postType, setPostType] = useState(WATERQUALITY);
  const [createPost, { serverErrors }] = useMangedMutation(
    MUTATION_CREATE_POST,
    {
      refetchQueries: [
        {
          query: QUERY_POSTS,
          variables: { placeId: props.placeId, language: "DA" },
        },
      ],
    }
  );

  useEffect(() => {
    if (!copiedPost) return;
    if (copiedPost.type === WATERQUALITY) {
      setPostType(WATERQUALITY);
    } else {
      setPostType(INFORMATION);
    }
  }, [copiedPost]);

  const onPostTypeChange = (e) => {
    setPostType(e.target.value);
  };

  const onClearAll = () => {
    props.onClearPost();
    setPostType(WATERQUALITY);
  };

  const onSubmit = async (values, actions) => {
    try {
      if (postType === WATERQUALITY) {
        await submitWaterquality(values);
      } else {
        await submitGeneralInfo(values);
      }
      actions.setSubmitting(false);
      actions.resetForm();
      props.onClearPost();
    } catch (error) {
      console.log(error);
    }
  };

  const submitWaterquality = async (values) => {
    const data = formatToDb(values);
    await createPost({
      variables: {
        postDate: values.date,
        placeId: props.placeId,
        elements: data,
        language: "DA",
        type: WATERQUALITY,
      },
    });
  };

  const submitGeneralInfo = async (values) => {
    const data = Info_formatToDb(values);
    await createPost({
      variables: {
        postDate: values.date,
        placeId: props.placeId,
        elements: data,
        language: "DA",
        type: INFORMATION,
      },
    });
  };

  return (
    <Section title="Create Post">
      <Controls
        type={postType}
        disableRemoveBtn={!copiedPost}
        onTypeChange={onPostTypeChange}
        onClearAll={onClearAll}
      />
      {postType === WATERQUALITY && (
        <PostForm
          initialValues={
            copiedPost && copiedPost.type === WATERQUALITY
              ? formatToForm(copiedPost.elements, "")
              : getInitialValues()
          }
          onSubmit={onSubmit}
          label="CREATE"
          serverErrors={serverErrors ? serverErrors : []}
        />
      )}
      {postType === INFORMATION && (
        <InfoPostForm
          initialValues={
            copiedPost && copiedPost.type === INFORMATION
              ? Info_formatToForm(copiedPost.elements, "")
              : Info_getInitialValues()
          }
          onSubmit={onSubmit}
          label="CREATE"
          serverErrors={serverErrors ? serverErrors : []}
        />
      )}
    </Section>
  );
};

export default CreatePostContainer;
