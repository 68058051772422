import React, { useState } from "react";

//CSS
import ss from "./LoggedInButton.module.css";
import { makeStyles } from "@material-ui/core/styles";

//Components
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

const useStyle = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.secondary.light,
  },
}));

const LoggedInButton = (props) => {
  const classes = useStyle();
  const [anchorEl, setAnchorEl] = useState(null);

  const onOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const getShortName = () => {
    const first = props.firstName.charAt(0);
    const last = props.lastName.charAt(0);
    const short = first + last;
    return short.toUpperCase();
  };
  return (
    <div>
      <IconButton
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={onOpen}
        //style={{ padding: 0 }}
        className={ss.iconButton}
      >
        <Avatar className={classes.avatar}>{getShortName()}</Avatar>
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={anchorEl !== null}
        onClose={onClose}
      >
        <MenuItem onClick={props.onProfileClicked}>Profile</MenuItem>
        <MenuItem onClick={props.onLogoutClicked}>Logout</MenuItem>
      </Menu>
    </div>
  );
};

export default LoggedInButton;
