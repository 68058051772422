import React from "react";

//css
import ss from "./UnitsList.module.css";

import { Button } from "@material-ui/core";

const UnitsList = (props) => {
  return (
    <div className={ss.container}>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={props.reload}
      >
        RELOAD
      </Button>
      <div className={ss.units}>{props.children}</div>
    </div>
  );
};

export default UnitsList;
