import React from "react";
import Controls from "./Layout/Controls";
import { useHistory } from "react-router";

const NavigationControls = (props) => {
  const history = useHistory();

  const onGoBack = () => {
    history.goBack();
  };

  return <Controls onGoBack={onGoBack} />;
};

export default NavigationControls;
