import React from "react";

//CSS
import ss from "./Controls.module.css";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Typography } from "@material-ui/core";

const Controls = (props) => {
  return (
    <div className={ss.controlsContainer}>
      <div className={ss.backContainer} onClick={props.onGoBack}>
        <ArrowBackIosIcon color="secondary" />
        <Typography variant="button">back</Typography>
      </div>
    </div>
  );
};

export default Controls;
