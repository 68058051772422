import React, { useState } from "react";

const DrawerContext = React.createContext({
  drawerOpen: false,
  subListsOpen: {},
  setDrawer: (state) => {},
  toggleDrawer: () => {},
  setSubList: (name, state) => {},
  toggleSubList: (name) => {},
});

export const DrawerContextProvider = (props) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [subList, setSubList] = useState({});

  const onSetDrawer = (state) => {
    setDrawerOpen(state);
  };

  const onToggleDrawer = () => {
    setDrawerOpen((prev) => !prev);
  };

  const onSetSubList = (name, state) => {
    setSubList((prev) => ({
      ...prev,
      [name]: state,
    }));
  };

  const onToggleSubList = (name) => {
    setSubList((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };

  const contextValue = {
    drawerOpen,
    subListsOpen: subList,
    setDrawer: onSetDrawer,
    toggleDrawer: onToggleDrawer,
    setSubList: onSetSubList,
    toggleSubList: onToggleSubList,
  };

  return (
    <DrawerContext.Provider value={contextValue}>
      {props.children}
    </DrawerContext.Provider>
  );
};

export default DrawerContext;
