import React from "react";

import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import AppsIcon from "@material-ui/icons/Apps";
import SettingsIcon from "@material-ui/icons/Settings";
import EditLocationIcon from "@material-ui/icons/EditLocation";
import PlaceIcon from "@material-ui/icons/Place";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import HistoryIcon from "@material-ui/icons/History";

const IconSelector = (props) => {
  switch (props.icon) {
    case MOBILE:
      return <PhoneIphoneIcon color="inherit" />;

    case USERS:
      return <PeopleAltIcon color="inherit" />;

    case APPS:
      return <AppsIcon color="inherit" />;

    case SETTINGS:
      return <SettingsIcon color="inherit" />;

    case PLACES:
      return <PlaceIcon color="inherit" />;

    case EDIT_PLACE:
      return <EditLocationIcon color="inherit" />;

    case MONITOR:
      return <DesktopWindowsIcon color="inherit" />;

    case HISTORY:
      return <HistoryIcon color="inherit" />;

    default:
      return <FiberManualRecordIcon />;
  }
};

export default IconSelector;

export const APPS = "APPS";
export const MOBILE = "MOBILE";
export const USERS = "USERS";
export const SETTINGS = "SETTINGS";
export const PLACES = "PLACES";
export const EDIT_PLACE = "EDIT_PLACE";
export const MONITOR = "MONITOR";
export const HISTORY = "HISTORY";
