import React from "react";
import { useField } from "formik";

//Components
import TextField from "@material-ui/core/TextField";

const ValidatedTextField = (props) => {
  const [field, meta] = useField(props);
  const hasError = meta.error && meta.touched;
  const errorText = hasError ? meta.error : "";
  return (
    <TextField {...field} {...props} helperText={errorText} error={hasError} />
  );
};

export default ValidatedTextField;
