import React from "react";
import { useField, useFormikContext } from "formik";
import moment from "moment";
import MomentUtils from "@date-io/moment";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const KeyboardDatePickerWrapper = (props) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);
  const hasError = meta.error && meta.touched;
  const errorText = hasError ? meta.error : "";
  return (
    <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
      <KeyboardDatePicker
        variant="inline"
        margin="normal"
        id="date-picker-inline"
        error={hasError}
        helperText={errorText}
        {...props}
        {...field}
        onChange={(value) => setFieldValue(field.name, value)}
      />
    </MuiPickersUtilsProvider>
  );
};

export default KeyboardDatePickerWrapper;
