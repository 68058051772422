import React from "react";
import { useField } from "formik";

//Components
import { FormControlLabel, Switch } from "@material-ui/core";

const SwitchWithLabel = ({ label, ...props }) => {
  const [field] = useField(props);
  return (
    <FormControlLabel
      control={<Switch {...props} {...field} />}
      label={label}
    />
  );
};

export default SwitchWithLabel;
