import React from "react";
import { FieldArray } from "formik";
import { v4 as uuidv4 } from "uuid";

//Components
import ActionsLayout from "../ActionLayout/ActionsLayout";
import HyperlinkLayout from "./Layout/HyperlinkLayout";

const HyperlinkArray = (props) => {
  return (
    <FieldArray name={props.arrayName}>
      {(helpers) => {
        const onDelete = (index) => {
          helpers.remove(index);
        };

        const onAdd = () => {
          helpers.push(getInitItem());
        };

        return (
          <ActionsLayout onAdd={onAdd} label="Add Hyperlink">
            {props.items.map((el, index) => {
              return (
                <HyperlinkLayout
                  key={el.id}
                  arrayName={props.arrayName}
                  index={index}
                  onDelete={onDelete.bind(null, index)}
                />
              );
            })}
          </ActionsLayout>
        );
      }}
    </FieldArray>
  );
};

export default HyperlinkArray;

export const getInitItem = () => {
  return { id: uuidv4(), text: "", url: "" };
};
