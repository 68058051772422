import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import AuthContext from "../../../context/auth/auth-context";
import { hasPermission } from "../../../models/user-access/UserAccess";

const GuardedRoute = (props) => {
  const authContext = useContext(AuthContext);

  if (!authContext.isLoggedIn) {
    return <Redirect push to="/login" from={props.path} />;
  }

  if (!hasPermission(props.path, authContext.user.permissions)) {
    return <Redirect push to="/noaccess" from={props.path} />;
  }

  return <Route path={props.path}>{props.children}</Route>;
};

export default GuardedRoute;
