import React from "react";

import {
  ICON_CHECK,
  ICON_CLOCK,
  ICON_DANGER,
  ICON_INFO,
  ICON_WARNING,
  ICON_PARKING,
  ICON_LOCATION,
  ICON_ACHIEVEMENT,
} from "../../../constants/IconTypes";
import {
  CheckmarkCircleOutline,
  InformationCircleOutline,
  WarningOutline,
  TimeOutline,
  LocationSharp,
} from "react-ionicons";
import ErrorIcon from "@material-ui/icons/Error";
import LocalParkingIcon from "@material-ui/icons/LocalParking";

const Icons = (props) => {
  switch (props.type) {
    case ICON_ACHIEVEMENT:
    case ICON_CHECK:
      return (
        <CheckmarkCircleOutline color="green" width="28px" height="28px" />
      );

    case ICON_WARNING:
      return <WarningOutline color="#ffba18" width="28px" height="28px" />;

    case ICON_DANGER:
      return <ErrorIcon style={{ fontSize: 28, color: "#f00000" }} />;

    case ICON_CLOCK:
      return <TimeOutline color="#2392ba" width="28px" height="28px" />;

    case ICON_PARKING:
      return <LocalParkingIcon style={{ fontSize: 28, color: "#0000ff" }} />;

    case ICON_LOCATION:
      return <LocationSharp color="#FF3D00" width="28px" height="28px" />;

    case ICON_INFO:
    default:
      return (
        <InformationCircleOutline color="#2392ba" width="28px" height="28px" />
      );
  }
};

export default Icons;
