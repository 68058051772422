import React from "react";
import { Route, Switch } from "react-router-dom/cjs/react-router-dom.min";
import { P_SWIMSPOTS_IFRAME } from "../../../models/user-access/UserAccess";
import PlacesIframeContainer from "./PlacesIframe/PlacesIframeContainer";

const SubPagesRouter = () => {
  return (
    <Switch>
      <Route exact path={P_SWIMSPOTS_IFRAME}>
        <PlacesIframeContainer />
      </Route>
    </Switch>
  );
};

export default SubPagesRouter;
