import { Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

//CSS
import ss from "./NoAccessMsg.module.css";

const NoAccessMsg = (props) => {
  return (
    <div className={ss.container}>
      <Typography variant="h3" color="primary">
        You do not have access to the service
      </Typography>
      <Typography variant="body1">
        Please contact us on info@waterwebtools.com to get access.
      </Typography>
      <Link to="/">
        <Typography variant="body1">Go back to overview</Typography>
      </Link>
    </div>
  );
};

export default NoAccessMsg;
