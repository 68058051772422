import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import { getRefreshToken } from "../../context/auth/auth-context";

const refreshClient = new ApolloClient({
  cache: new InMemoryCache(),
  uri: process.env.REACT_APP_BACKEND_URL_API,
});

const REFRESH_TOKEN = gql`
  mutation refreshToken($refreshToken: String!) {
    refreshAccessToken(refreshToken: $refreshToken) {
      token
    }
  }
`;

export const getNewToken = async () => {
  try {
    const data = await refreshClient.mutate({
      mutation: REFRESH_TOKEN,
      variables: { refreshToken: getRefreshToken() },
    });
    return data.data.refreshAccessToken.token;
  } catch (error) {
    throw error;
  }
};
