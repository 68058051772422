import React from "react";

//CSS
import ss from "./LoginPageLayout.module.css";

//Components
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ValidationErrorViewer from "../../../UI/ValidationErrorViewer/ValidationErrorViewer";

const LoginPageLayout = (props) => {
  return (
    <div className={ss.container}>
      <ValidationErrorViewer messages={props.validationErrors} />
      <form className={ss.form} onSubmit={props.onSubmit}>
        <TextField
          label="Email"
          type="email"
          margin="dense"
          fullWidth
          name="email"
          value={props.email}
          onChange={props.onEmailChange}
        />
        <TextField
          label="Password"
          type="password"
          margin="dense"
          fullWidth
          name="password"
          value={props.password}
          onChange={props.onPasswordChange}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          className={ss.loginButton}
        >
          Login
        </Button>
      </form>
    </div>
  );
};

export default LoginPageLayout;
