import React from "react";
import { Typography } from "@material-ui/core";

//Css
import ss from "./PlaceRow.module.css";

//Components
import ToolTipIconButton from "../../../../../../UI/ToolTipIconButton/ToolTipIconButton";
import EditIcon from "@material-ui/icons/Edit";

const PlaceRow = (props) => {
  return (
    <div className={ss.row}>
      <Typography variant="h6">{props.name}</Typography>
      <div className={ss.btnContainer}>
        <ToolTipIconButton title="Edit" onClick={props.onEdit}>
          <EditIcon color="secondary" />
        </ToolTipIconButton>
      </div>
    </div>
  );
};

export default PlaceRow;
