import React, { Fragment } from "react";
import { Formik } from "formik";
import * as Yup from "yup";

//Css
import ss from "./DeleteUserForm.module.css";

//Components
import WarningButton from "../../../../../UI/WarningButton/WarningButton";
import ValidatedTextField from "../../../../../UI/Form/ValidatedTextField/ValidatedTextField";
import ValidationErrorViewer from "../../../../../UI/ValidationErrorViewer/ValidationErrorViewer";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Not a valid email"),
});

const DeleteUserForm = (props) => {
  return (
    <Formik
      initialValues={props.initialValues}
      onSubmit={props.onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ handleSubmit, isSubmitting }) => (
        <Fragment>
          <ValidationErrorViewer messages={props.serverErrors} />
          <form onSubmit={handleSubmit} className={ss.form}>
            <ValidatedTextField
              type="email"
              name="email"
              label="User email"
              fullWidth
              className={ss.input}
            />
            <WarningButton
              type="submit"
              fullWidth
              variant="contained"
              //color="danger"
              disabled={isSubmitting}
            >
              Delete
            </WarningButton>
          </form>
        </Fragment>
      )}
    </Formik>
  );
};

export default DeleteUserForm;
