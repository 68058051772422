import React from "react";
import { useQuery } from "@apollo/client";
import {
  MUTATION_UPDATE_POST,
  QUERY_POST,
} from "../../../../../../network/gql/mobile-app-service";
import useMangedMutation from "../../../../../../hooks/useManagedMutation";
import {
  WATERQUALITY,
  INFORMATION,
} from "../../../../../../constants/PostTypes";

//Components
import Section from "../../../../../UI/Section/Section";
import GQLQueryErrorHandler from "../../../../../UI/GQLQueryErrorHandler/GQLQueryErrorHandler";
import PostForm from "../../Layout/Post/PostForm";
import InfoPostForm from "../../Layout/InfoPost/InfoPostForm";
import {
  formatToForm,
  formatToDb,
} from "../../../../../../models/mobile-app/post-formatter-model";
import {
  formatToForm as Info_formatToForm,
  formatToDb as Info_formatToDb,
} from "../../../../../../models/mobile-app/info-post-formatter-model";

const EditPostFormContainer = (props) => {
  const { data, error } = useQuery(QUERY_POST, {
    variables: { id: props.id },
  });
  const [updatePost, { serverErrors }] =
    useMangedMutation(MUTATION_UPDATE_POST);

  const onSubmit = async (values, actions) => {
    try {
      const elements =
        data.post.type === WATERQUALITY
          ? formatToDb(values)
          : Info_formatToDb(values);
      updatePost({
        variables: { id: props.id, elements: elements, postDate: values.date },
      });
      actions.resetForm();
    } catch (error) {
      console.log(error);
    }
    actions.setSubmitting(false);
  };

  return (
    <Section title="Edit Post">
      <GQLQueryErrorHandler error={error} />
      {data && data.post.type === WATERQUALITY && (
        <PostForm
          initialValues={formatToForm(data.post.elements, data.post.postDate)}
          onSubmit={onSubmit}
          label="UPDATE"
          serverErrors={serverErrors ? serverErrors : []}
        />
      )}
      {data && data.post.type === INFORMATION && (
        <InfoPostForm
          initialValues={Info_formatToForm(
            data.post.elements,
            data.post.postDate
          )}
          onSubmit={onSubmit}
          label="UPDATE"
          serverErrors={serverErrors ? serverErrors : []}
        />
      )}
    </Section>
  );
};

export default EditPostFormContainer;
