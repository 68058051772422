import React, { useState, useContext } from "react";
import { useQuery } from "@apollo/client";
import HeaderDrawerFrame from "../../../Base/Frames/HeaderDrawerFrame/HeaderDrawerFrame";
import PageView from "../../../Base/Frames/PageView/PageView";
import { QUERY_USER_PLACES } from "../../../../network/gql/mobile-app-service";
import GQLQueryErrorHandler from "../../../UI/GQLQueryErrorHandler/GQLQueryErrorHandler";
import CardPlacesList from "./CardPlacesList/CardPlacesList";
import { useHistory } from "react-router";
import SearchBar from "../../../UI/SearchBar/SearchBar";
import SearchPlacesContext from "../../../../context/MobilePlacesSearch/search-places-context";

const searchFilter = (data, searchFilter) => {
  if (!data) return null;
  if (!searchFilter || searchFilter === "") return data;
  const filter = searchFilter.toUpperCase();
  return data.filter((p) => {
    if (p.name.toUpperCase().indexOf(filter) > -1) return true;
    if (p.description && p.description.toUpperCase().indexOf(filter) > -1)
      return true;
    if (p.owner && p.owner.toUpperCase().indexOf(filter) > -1) return true;
    return false;
  });
};

const OverviewPageContainer = (props) => {
  //const [searchTxt, setSearchTxt] = useState("");
  const searchCtx = useContext(SearchPlacesContext);
  const history = useHistory();
  const { data, loading, error } = useQuery(QUERY_USER_PLACES, {
    fetchPolicy: "network-only",
  });

  const onAddPost = (id) => {
    history.push(`/mobile-app/places/${id}/posts`);
  };

  const onEditInfo = (id) => {
    history.push(`/mobile-app/places/${id}/info`);
  };

  const onSearchChange = (e) => {
    searchCtx.setSearchTxt(e.target.value);
  };

  const onSearchClear = () => {
    searchCtx.setSearchTxt("");
  };

  const filteredPlaces = data
    ? searchFilter(data.userPlaces, searchCtx.searchTxt)
    : null;

  return (
    <HeaderDrawerFrame persistent={true}>
      <PageView>
        <GQLQueryErrorHandler error={error} />
        <SearchBar
          value={searchCtx.searchTxt}
          onChange={onSearchChange}
          onClear={onSearchClear}
          disabled={data === null}
        />
        {loading && <p>Loading...</p>}
        {filteredPlaces && (
          <CardPlacesList
            places={filteredPlaces}
            onAddPost={onAddPost}
            onEditInfo={onEditInfo}
          />
        )}
      </PageView>
    </HeaderDrawerFrame>
  );
};

export default OverviewPageContainer;
