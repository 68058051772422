import { v4 as uuidv4 } from "uuid";
import { BODYTEXT, ICON_STATEMENT, DATAPOINT } from "./constants";

export const formatToDb = (norm) => {
  const denom = {};
  denom.description = denomBody(norm.description);
  denom.statements = denomStatements(norm.statements);
  denom.datapoints = denomDatapoints(norm.datapoints);

  return denom;
};

const denomBody = (text) => {
  return {
    id: uuidv4(),
    type: BODYTEXT,
    payload: {
      text,
    },
  };
};

const denomStatements = (statements) => {
  return statements.map((s) => {
    return {
      id: s.id,
      type: ICON_STATEMENT,
      payload: {
        icon: s.icon,
        text: s.text,
      },
    };
  });
};

const denomDatapoints = (datapoints) => {
  return datapoints.map((d) => {
    return {
      id: d.id,
      type: DATAPOINT,
      payload: {
        label: d.label,
        unit: d.unit,
        value: d.value,
        quality: d.quality,
        rangeMin: d.rangeMin,
        rangeMax: d.rangeMax,
        limitMin: d.limitMin,
        limitMax: d.limitMax,
      },
    };
  });
};

export const formatToForm = (values, postDate) => {
  const form = {};

  form.date = postDate;
  form.description = normBody(values.description);
  form.statements = normStatements(values.statements);
  form.datapoints = normDataPoints(values.datapoints);

  return form;
};

const normBody = (data) => {
  return data.payload.text;
};

const normStatements = (statements) => {
  return statements.map((s) => {
    return {
      id: s.id,
      icon: s.payload.icon,
      text: s.payload.text,
    };
  });
};

const normDataPoints = (datapoints) => {
  return datapoints.map((dp) => {
    return {
      ...dp.payload,
      id: dp.id,
    };
  });
};
