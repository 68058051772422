import React, { Fragment } from "react";
import { Formik } from "formik";
import moment from "moment";
import * as Yup from "yup";
import * as _ from "lodash";

import ss from "./FlagInputForm.module.css";

import { Button, Typography } from "@material-ui/core";
import SwitchWithLabel from "../../../../UI/Form/SwitchWithLabel/SwitchWithLabel";
import ValidatedTextField from "../../../../UI/Form/ValidatedTextField/ValidatedTextField";
//import DatePicker from "../../../../UI/Form/DatePicker/DatePicker";
import ToolTipIconButton from "../../../../UI/ToolTipIconButton/ToolTipIconButton";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ValidationErrorViewer from "../../../../UI/ValidationErrorViewer/ValidationErrorViewer";
import KeyboardDatePickerWrapper from "../../../../UI/Form/DatePicker/KeyboardDatePicker";

const validationSchema = Yup.object().shape({
  description: Yup.string().max(50, "Too long"),
  seasonFrom: Yup.date()
    .nullable()
    .max(Yup.ref("seasonTo"), "Must be before Season to")
    .when("seasonTo", {
      is: (val) => val !== null,
      then: Yup.date().nullable().required(),
    }),
  seasonTo: Yup.date()
    .nullable()
    .min(Yup.ref("seasonFrom"), "Must be after Season from"),
});

const FlagInputForm = (props) => {
  return (
    <Formik
      initialValues={props.initialValues}
      onSubmit={props.onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ handleSubmit, values, isSubmitting, initialValues, errors }) => (
        <Fragment>
          <ValidationErrorViewer messages={props.serverErrors} />
          <form onSubmit={handleSubmit} className={ss.form}>
            <div className={ss.header}>
              <Typography variant="h6" color="primary">
                {props.title}
              </Typography>
              <div className={ss.buttons}>
                <SwitchWithLabel
                  name="show"
                  type="checkbox"
                  label="Show flag"
                  disabled={props.disabled}
                />

                <ToolTipIconButton
                  title="Clear flag info"
                  onClick={props.onClear}
                  disabled={props.disabled || props.deleteDisabled}
                >
                  <HighlightOffIcon
                    color={
                      props.disabled || props.deleteDisabled
                        ? "disabled"
                        : "primary"
                    }
                  />
                </ToolTipIconButton>
              </div>
            </div>
            {props.disabled && (
              <Typography>
                Flag is controlled by a service. Contact us to change the flag.
              </Typography>
            )}
            <ValidatedTextField
              name="description"
              type="input"
              label="Description"
              className={ss.input}
              fullWidth
              disabled={props.disabled}
            />
            <div className={ss.pickerBox}>
              <div className={ss.datepicker}>
                <KeyboardDatePickerWrapper
                  name="seasonFrom"
                  label="Season start"
                  format="DD-MM-yyyy"
                  maxDate={moment().add(10, "years")}
                  fullWidth={true}
                  disabled={props.disabled}
                />
              </div>
              <div className={ss.datepicker}>
                <KeyboardDatePickerWrapper
                  name="seasonTo"
                  label="Season end"
                  format="DD-MM-yyyy"
                  maxDate={moment().add(10, "years")}
                  fullWidth={true}
                  disabled={props.disabled}
                />
              </div>
            </div>
            <Button
              type="submit"
              fullWidth
              variant="text"
              color="primary"
              disabled={
                isSubmitting ||
                _.isEqual(values, initialValues) ||
                props.disabled
              }
            >
              Update
            </Button>
          </form>
        </Fragment>
      )}
    </Formik>
  );
};

export const getInitialValues = () => {
  return {
    show: false,
    description: "",
    seasonFrom: null,
    seasonTo: null,
  };
};

export default FlagInputForm;
