import React from "react";

//CSS
import ss from "./Table.module.css";

const Table = (props) => {
  return <div className={ss.table}>{props.children}</div>;
};

export default Table;
