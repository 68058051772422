import React from "react";

//CSS
import ss from "./Section.module.css";

//Components
import { Typography } from "@material-ui/core";

const Section = (props) => {
  return (
    <section className={ss.contianer}>
      <div className={ss.titleBox}>
        <Typography variant="h5">{props.title}</Typography>
      </div>
      <div className={ss.content}>{props.children}</div>
    </section>
  );
};

export default Section;
