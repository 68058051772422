import React from "react";
import { Button } from "@material-ui/core";

const WarningButton = (props) => {
  const style = !props.disabled
    ? { background: "#e53935", color: "white" }
    : {};

  return (
    <Button {...props} style={style}>
      {props.children}
    </Button>
  );
};

export default WarningButton;
