import React, { useEffect } from "react";
import { QUERY_ALL_PLACES } from "../../../../../network/gql/mobile-app-service";
import { useLazyQuery } from "@apollo/client";
import { useHistory } from "react-router";

//Components
import Section from "../../../../UI/Section/Section";
import PlaceRow from "./Layout/PlaceRow/PlaceRow";
import GQLQueryErrorHandler from "../../../../UI/GQLQueryErrorHandler/GQLQueryErrorHandler";
import * as _ from "lodash";

const Places = (props) => {
  const history = useHistory();
  const [queryPlaces, { loading, error, data }] = useLazyQuery(
    QUERY_ALL_PLACES,
    {}
  );

  useEffect(() => queryPlaces(), [queryPlaces]);

  const onEdit = (id) => {
    history.push(`/mobile-app/configure/${id}`);
  };

  const sortedPlaces = !data ? [] : _.orderBy(data.places, "name", "asc");

  return (
    <Section title="Places">
      <GQLQueryErrorHandler error={error} />
      {loading && !error ? <p>Loading</p> : null}
      {data &&
        sortedPlaces.map((p) => (
          <PlaceRow key={p.id} name={p.name} onEdit={onEdit.bind(null, p.id)} />
        ))}
    </Section>
  );
};

export default Places;
