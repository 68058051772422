import React from "react";

//css
import ss from "../BarGraph.module.css";

import { Typography } from "@material-ui/core";

const Labels = (props) => {
  return (
    <div className={ss.labels}>
      <Typography variant="body1">{props.minRange}</Typography>
      <Typography variant="body1">{props.maxRange}</Typography>
    </div>
  );
};

export default Labels;
