import { gql } from "@apollo/client";

export const QUERY_USER_ID_BY_EMAIL = gql`
  query getUserIdByEmail($email: EmailAddress!) {
    userByEmail(email: $email) {
      id
      email
    }
  }
`;

export const QUERY_USER_BY_ID = gql`
  query getUserById($id: String!) {
    user(id: $id) {
      email
      firstName
      lastName
    }
  }
`;

export const MUTATION_USER_CHANGE_PASSWORD = gql`
  mutation changePassword(
    $oldPassword: String!
    $password1: String!
    $password2: String!
  ) {
    userSetPassword(
      oldPassword: $oldPassword
      password1: $password1
      password2: $password2
    ) {
      id
    }
  }
`;
