import React from "react";

//Components
import HeaderDrawerFrame from "../../../Base/Frames/HeaderDrawerFrame/HeaderDrawerFrame";
import CreateNewUser from "./CreateNewUser/CreateNewUser";
import PageView from "../../../Base/Frames/PageView/PageView";
import UserList from "./UsersList/UserList";

const UsersPage = () => {
  return (
    <HeaderDrawerFrame persistent={true}>
      <PageView>
        <CreateNewUser />
        <UserList />
      </PageView>
    </HeaderDrawerFrame>
  );
};

export default UsersPage;
