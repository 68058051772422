import React from "react";

//CSS
import ss from "./Statement.module.css";

//Icons
import { Typography } from "@material-ui/core";
import Icons from "../../../Icons/Icons";

const Statement = (props) => {
  return (
    <div className={ss.container}>
      <span className={ss.iconWrapper}>
        <Icons type={props.icon} />
      </span>
      <Typography variant="body1">{props.text}</Typography>
    </div>
  );
};

export default Statement;
