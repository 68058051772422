import { Typography } from "@material-ui/core";
import moment from "moment-timezone";
import React from "react";

//css
import ss from "./StateLogList.module.css";

const StateLogList = (props) => {
  return (
    <div className={ss.list}>
      {[...props.logs].reverse().map((info) => (
        <div className={ss.logContainer}>
          <div>
            <Typography>{info.state}</Typography>
            {info.diff ? <Typography>{info.diff} minutes</Typography> : null}
          </div>
          <Typography>
            {moment(info.timestamp).format("HH:mm ddd D. MMM YY")}
          </Typography>
        </div>
      ))}
    </div>
  );
};

export default StateLogList;
