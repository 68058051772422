import React, { useState } from "react";

const SearchPlacesContext = React.createContext({
  searchTxt: "",
  setSearchTxt: (txt) => {},
});

export const SearchPlacesContextProvider = (props) => {
  const [searchTxt, setSearchTxt] = useState("");

  const onSetSearchTxt = (txt) => {
    setSearchTxt(txt);
  };

  const contextValue = {
    searchTxt,
    setSearchTxt: onSetSearchTxt,
  };

  return (
    <SearchPlacesContext.Provider value={contextValue}>
      {props.children}
    </SearchPlacesContext.Provider>
  );
};

export default SearchPlacesContext;
