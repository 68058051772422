import React from "react";

//CSS
import ss from "../BarGraph.module.css";

const Bar = (props) => {
  return (
    <div className={ss.barOuter}>
      <div
        className={ss.barInner}
        style={{
          width: `${props.relativeValue}%`,
          backgroundColor: props.color,
        }}
      />
      {props.lowLimitRelative > 0 && (
        <div
          className={ss.barLimit}
          style={{ width: `${props.lowLimitRelative}%` }}
        />
      )}

      {props.highLimitRelative > 0 && (
        <div
          className={ss.barLimit}
          style={{ width: `${props.highLimitRelative}%` }}
        />
      )}
    </div>
  );
};

export default Bar;
