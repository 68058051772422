import { gql } from "@apollo/client";

export const QUERY_ALL_PLACES = gql`
  query allPlaces {
    places {
      id
      name
    }
  }
`;

export const MUTATION_CREATE_PLACE = gql`
  mutation createPlace($InputPlace: InputPlace!) {
    createPlace(place: $InputPlace) {
      id
      name
    }
  }
`;

export const MUTATION_UPDATE_PLACE = gql`
  mutation updatePlace($id: String!, $InputPlace: InputPlace!) {
    updatePlace(id: $id, place: $InputPlace) {
      id
      name
      description
      url
      owner
      lat
      lng
      depth
      liveDayDataQuery {
        type
        id
      }
      forecastedDayDataQuery {
        type
        id
        fromDay
        toDay
      }
      forecastedTableDataQuery {
        type
        id
        fromDay
        toDay
      }
    }
  }
`;

export const QUERY_PLACE = gql`
  query place($id: String!) {
    place(id: $id) {
      id
      name
      description
      url
      owner
      lat
      lng
      depth
      liveDayDataQuery {
        type
        id
      }
      forecastedDayDataQuery {
        type
        id
        fromDay
        toDay
      }
      forecastedTableDataQuery {
        type
        id
        fromDay
        toDay
      }
    }
  }
`;

export const QUERY_PLACE_LIGHT = gql`
  query placeLight($id: String!) {
    place(id: $id) {
      id
      imagePath
    }
  }
`;

export const MUTATION_DELETE_PLACE = gql`
  mutation deletePlace($id: String!, $name: String!) {
    deletePlace(id: $id, name: $name) {
      id
    }
  }
`;

export const MUTATION_ADD_EDITOR = gql`
  mutation addEditor($placeId: String!, $userId: String!) {
    addEditorToPlace(placeId: $placeId, userId: $userId) {
      id
      userId
    }
  }
`;

export const QUERY_PLACE_EDITORS = gql`
  query getPlaceEditors($placeId: String!) {
    placeEditors(placeId: $placeId) {
      userId
    }
  }
`;

export const MUTATION_DELETE_EDITOR = gql`
  mutation deleteEditorFromPlace($placeId: String!, $userId: String!) {
    deleteEditorFromPlace(placeId: $placeId, userId: $userId) {
      userId
    }
  }
`;

export const QUERY_USER_PLACES = gql`
  query userPlaces {
    userPlaces {
      id
      lat
      lng
      name
      description
      url
      imagePath
      owner
    }
  }
`;

export const QUERY_PLACES_LIGHT = gql`
  query places {
    places {
      id
      name
      description
      owner
      imagePath
      liveDayDataQuery {
        type
        id
      }
    }
  }
`;

export const MUTATION_UPSERT_INFO_ELEMENTS = gql`
  mutation upsertInfoElements(
    $placeId: String!
    $language: String!
    $infoElements: JSON!
  ) {
    upsertInfoElements(
      placeId: $placeId
      language: $language
      infoElements: $infoElements
    ) {
      id
      language
      elements
      updatedAt
      createdAt
    }
  }
`;

export const QUERY_INFO_ELEMENTS = gql`
  query infoElements($placeId: String!, $language: String!) {
    infoElements(placeId: $placeId, language: $language) {
      id
      language
      elements
      updatedAt
      createdAt
    }
  }
`;

export const MUTATION_CREATE_POST = gql`
  mutation createPost(
    $placeId: String!
    $type: POST_TYPES!
    $language: String!
    $elements: JSON!
    $postDate: DateTime!
  ) {
    createPost(
      placeId: $placeId
      type: $type
      language: $language
      elements: $elements
      postDate: $postDate
    ) {
      id
      language
      elements
      createdAt
      updatedAt
    }
  }
`;

export const QUERY_POSTS = gql`
  query posts($placeId: String!, $language: String!, $type: POST_TYPES) {
    posts(placeId: $placeId, language: $language, type: $type) {
      id
      postDate
      type
      language
      elements
    }
  }
`;

export const QUERY_POST = gql`
  query post($id: String!) {
    post(id: $id) {
      id
      postDate
      type
      language
      elements
    }
  }
`;

export const MUTATION_UPDATE_POST = gql`
  mutation updatePost($id: String!, $elements: JSON, $postDate: DateTime) {
    updatePost(id: $id, postDate: $postDate, elements: $elements) {
      id
      postDate
      type
      language
      elements
    }
  }
`;

export const MUTATION_DELETE_POST = gql`
  mutation deletePost($id: String!) {
    deletePost(id: $id) {
      id
    }
  }
`;

export const QUERY_PLACE_IMAGES = gql`
  query loadImages($placeId: String!) {
    placeImages(placeId: $placeId) {
      id
      path
      primary
    }
  }
`;

export const MUTATION_SET_IMAGE_PRIMARY = gql`
  mutation setImagePrimary($id: String!) {
    image: setPlaceImageToPrimary(id: $id) {
      id
      path
      primary
    }
  }
`;

export const MUTATION_DELETE_PLACE_IMAGE = gql`
  mutation deleteImage($id: String!) {
    image: deletePlaceImage(id: $id) {
      id
    }
  }
`;

export const MUTATION_CREATE_APP_VERSION = gql`
  mutation createAppVersion($version: InputAppVersion!) {
    createAppVersion(version: $version) {
      id
      version
      description_DA
      description_EN
      expoUpdate
      forceUpdate
      createdAt
    }
  }
`;

export const QUERY_APP_VERSIONS = gql`
  query appVerisons($latest: Int!) {
    appVersions(latest: $latest) {
      id
      version
      description_DA
      description_EN
      expoUpdate
      forceUpdate
      createdAt
    }
  }
`;

export const MUTATION_DELETE_APP_VERSION = gql`
  mutation deleteAppVersion($id: String!) {
    deleteAppVersion(id: $id) {
      id
    }
  }
`;

export const QUERY_FLAGS = gql`
  query placeFlages($placeId: String!) {
    flags(placeId: $placeId) {
      id
      flag
      description
      seasonFrom
      seasonTo
      disabled
      controlledByService
    }
  }
`;

export const MUTATION_UPSERT_FLAG = gql`
  mutation upsertFlag($placeId: String!, $flag: InputFlag!, $flagId: String) {
    upsertFlag(placeId: $placeId, flag: $flag, flagId: $flagId) {
      id
      flag
      description
      seasonFrom
      seasonTo
      disabled
      controlledByService
    }
  }
`;

export const MUTATION_DELETE_FLAG = gql`
  mutation deleteFlag($placeId: String!, $flagId: String!) {
    deleteFlag(placeId: $placeId, flagId: $flagId) {
      id
    }
  }
`;
