import React, { useState, useContext } from "react";
import { gql, useMutation } from "@apollo/client";
import AuthContext from "../../../context/auth/auth-context";
import { useHistory } from "react-router-dom";

//Components
import HeaderDrawerFrame from "../../Base/Frames/HeaderDrawerFrame/HeaderDrawerFrame";
import LoginPageLayout from "./Layout/LoginPageLayout";
import CritialErrorContext from "../../../context/critial-error/critial-error-context";

const UsersPage = () => {
  const authContext = useContext(AuthContext);
  const criticalErrorCtx = useContext(CritialErrorContext);
  const history = useHistory();
  const [login, { error }] = useMutation(LOGIN);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  //const [networkError, setNetworkError] = useState(null);

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      const data = await login({
        variables: { email: email, password: password },
      });
      const { token, refreshToken, user } = data.data.login;
      authContext.login(token, refreshToken, user);
      history.goBack();
    } catch (error) {
      if (error.networkError) {
        criticalErrorCtx.setError(
          error.networkError,
          "Unable to connect to server"
        );
      }
    }
  };

  return (
    <HeaderDrawerFrame persistent={true}>
      <LoginPageLayout
        validationErrors={
          error && error.graphQLErrors.map((error) => error.message)
        }
        email={email}
        password={password}
        onEmailChange={(e) => setEmail(e.target.value)}
        onPasswordChange={(e) => setPassword(e.target.value)}
        onSubmit={onSubmit}
      />
    </HeaderDrawerFrame>
  );
};

export default UsersPage;

/**
 * Queries
 */
const LOGIN = gql`
  mutation login($email: EmailAddress!, $password: String!) {
    login(email: $email, password: $password) {
      user {
        firstName
        lastName
        email
        permissions
      }
      token
      refreshToken
    }
  }
`;
