import { v4 as uuidv4 } from "uuid";
import { BODYTEXT, ICON_STATEMENT } from "./constants";

export const formatToDb = (norm) => {
  const denom = {};
  denom.description = denomBody(norm.description);
  denom.statements = denomStatements(norm.statements);

  return denom;
};

const denomBody = (text) => {
  return {
    id: uuidv4(),
    type: BODYTEXT,
    payload: {
      text,
    },
  };
};

const denomStatements = (statements) => {
  return statements.map((s) => {
    return {
      id: s.id,
      type: ICON_STATEMENT,
      payload: {
        icon: s.icon,
        text: s.text,
      },
    };
  });
};

export const formatToForm = (values, postDate) => {
  const form = {};

  form.date = postDate;
  form.description = normBody(values.description);
  form.statements = normStatements(values.statements);

  return form;
};

const normBody = (data) => {
  return data.payload.text;
};

const normStatements = (statements) => {
  return statements.map((s) => {
    return {
      id: s.id,
      icon: s.payload.icon,
      text: s.payload.text,
    };
  });
};
