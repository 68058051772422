import React, { useState } from "react";
import { useHistory } from "react-router";
import {
  INFORMATION,
  WATERQUALITY,
} from "../../../../../../constants/PostTypes";
import WQPostContainer from "../../../../../UI/PostView/WQPost/WQPostContainer";
import InfoPostContainer from "../../../../../UI/PostView/InfoPost/InfoPostContainer";

//Components
import TableRow from "./Layout/TableRow";

const TableRowContainer = (props) => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const { data } = props;

  const toggleShow = () => {
    setShow((state) => !state);
  };

  const onEdit = (id) => {
    history.push(`/mobile-app/places/${props.placeId}/posts/edit/${id}`);
  };

  return (
    <TableRow
      type={data.type}
      date={data.postDate}
      show={show}
      toggleShow={toggleShow}
      onEdit={onEdit.bind(null, data.id)}
      onCopy={props.onCopyPost}
    >
      {data.type === WATERQUALITY && (
        <WQPostContainer
          statements={props.elements.statements}
          datapoints={props.elements.datapoints}
          description={props.elements.description}
        />
      )}
      {data.type === INFORMATION && (
        <InfoPostContainer
          statements={props.elements.statements}
          description={props.elements.description}
        />
      )}
    </TableRow>
  );
};

export default TableRowContainer;
