import React, { useContext } from "react";
import { useMediaQuery } from "react-responsive";

//CSS
import ss from "./HeaderDrawerFrame.module.css";

//Compoents
import Header from "../../Elements/Header/Header";
import SideDrawer from "../../Elements/SideDrawer/SideDrawer";
import Content from "../../Elements/Content/Content";
import DrawerContext from "../../../../context/drawer/drawer-context";

const HeaderDrawerFrame = (props) => {
  const { drawerOpen, toggleDrawer, setDrawer } = useContext(DrawerContext);

  //Use temporary on small screen
  const isBigScreen = useMediaQuery({ minWidth: 800 });
  const drawerPersistent = isBigScreen && props.persistent;

  return (
    <div className={ss.container}>
      <Header drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} />
      <SideDrawer
        drawerOpen={drawerOpen}
        onCloseDrawer={() => setDrawer(false)}
        variant={drawerPersistent ? "persistent" : "temporary"}
      />
      <Content drawerOpen={drawerOpen} active={drawerPersistent}>
        {props.children}
        <div className={ss.footer}></div>
      </Content>
    </div>
  );
};

export default HeaderDrawerFrame;
