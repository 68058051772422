import React, { useContext } from "react";
import { gql, useMutation } from "@apollo/client";
import CritialErrorContext from "../../../../../context/critial-error/critial-error-context";
import { gqlErrorParser } from "../../../../UI/ValidationErrorViewer/ValidationErrorViewer";

//Components
import Section from "../../../../UI/Section/Section";
import NewPasswordForm from "./NewPasswordForm/NewPasswordForm";

const NewPassword = (props) => {
  const criticalErrorCtx = useContext(CritialErrorContext);
  const [setNewPassword, { error }] = useMutation(ADMIN_SET_PASSWORD);

  const onSetNewPassword = async (values, actions) => {
    try {
      await setNewPassword({
        variables: { id: props.user.id, ...values },
      });
      actions.setSubmitting(false);
      actions.resetForm();
    } catch (error) {
      if (error.networkError) {
        criticalErrorCtx.setError(
          error.networkError,
          "Unable to set new password"
        );
      }
      actions.setSubmitting(false);
    }
  };

  return (
    <Section title="Set New Password">
      <NewPasswordForm
        initialValues={{ password1: "", password2: "" }}
        onSubmit={onSetNewPassword}
        serverErrors={error && gqlErrorParser(error.graphQLErrors)}
      />
    </Section>
  );
};

export default NewPassword;

const ADMIN_SET_PASSWORD = gql`
  mutation adminSetPassword(
    $id: String!
    $password1: String!
    $password2: String!
  ) {
    adminSetPassword(id: $id, password1: $password1, password2: $password2) {
      id
    }
  }
`;
