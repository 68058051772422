import React, { Fragment } from "react";
import { Formik } from "formik";
import * as Yup from "yup";

//CSS
import ss from "./CreateNewUserLayout.module.css";

//Components
import { Button } from "@material-ui/core";
import ValidatedTextField from "../../../../../UI/Form/ValidatedTextField/ValidatedTextField";
import ValidationErrorViewer from "../../../../../UI/ValidationErrorViewer/ValidationErrorViewer";
import CheckList from "../../../../../UI/Form/CheckList/CheckList";

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("Required")
    .min(1, "Too short")
    .max(20, "Too long"),
  lastName: Yup.string()
    .required("Required")
    .min(2, "Too short")
    .max(20, "Too long"),
  email: Yup.string().required("Required").email("Not a valid email"),
  password1: Yup.string().required("Required"),
  password2: Yup.string()
    .oneOf([Yup.ref("password1"), null], "Passwords don't match!")
    .required("Required"),
});

const CreateNewUserLayout = (props) => {
  return (
    <Formik
      initialValues={props.initialValues}
      onSubmit={props.onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Fragment>
          <ValidationErrorViewer messages={props.serverErrors} />
          <form onSubmit={handleSubmit} className={ss.form}>
            <ValidatedTextField
              name="firstName"
              type="input"
              label="First name"
              className={ss.input}
              fullWidth
            />
            <ValidatedTextField
              type="input"
              name="lastName"
              label="Last name"
              fullWidth
              className={ss.input}
            />
            <ValidatedTextField
              type="email"
              name="email"
              label="Email"
              fullWidth
              className={ss.input}
            />
            <ValidatedTextField
              type="password"
              name="password1"
              label="Password"
              fullWidth
              className={ss.input}
            />
            <ValidatedTextField
              type="password"
              name="password2"
              label="Password again"
              fullWidth
              className={ss.input}
            />
            <CheckList
              name="permissions"
              title="Permissions"
              list={props.permissionList}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              Create
            </Button>
          </form>
        </Fragment>
      )}
    </Formik>
  );
};

export default CreateNewUserLayout;
