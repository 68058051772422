import React, { Fragment } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import * as _ from "lodash";

//Css
import ss from "./PlaceConfigForm.module.css";

//Components
import ValidationErrorViewer from "../../../../UI/ValidationErrorViewer/ValidationErrorViewer";
import { Button } from "@material-ui/core";
import ValidatedTextField from "../../../../UI/Form/ValidatedTextField/ValidatedTextField";
import SwitchWithLabel from "../../../../UI/Form/SwitchWithLabel/SwitchWithLabel";
import LDDQ from "./LDDQ/LDDQ";
import FDQ from "./FDQ/FDQ";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Required")
    .min(2, "Too short")
    .max(20, "Too long"),
  description: Yup.string()
    .required("Required")
    .min(2, "Too short")
    .max(30, "Too long"),
  url: Yup.string().url("Most be a URL"),
  owner: Yup.string().min(2, "Too short").max(40, "Too long"),
  lat: Yup.number()
    .required("Required")
    .min(-90, "Too small lat")
    .max(90, "Too big lat"),
  lng: Yup.number()
    .required("Required")
    .min(-180, "Too small lng")
    .max(180, "Too big lng"),
  depth: Yup.number()
    .required("Required")
    .min(0, "Too small depth")
    .max(50, "Too big depth"),
  LDDQ_enabled: Yup.boolean().required(),
  LDDQ_type: Yup.string().when("LDDQ_enabled", {
    is: true,
    then: Yup.string().required().min(1),
  }),
  LDDQ_id: Yup.string().when("LDDQ_enabled", {
    is: true,
    then: Yup.string().required().min(4, "Too short id"),
  }),
  FDDQ_enabled: Yup.boolean().required(),
  FDDQ_type: Yup.string().when("FDDQ_enabled", {
    is: true,
    then: Yup.string().required().min(1),
  }),
  FDDQ_id: Yup.string().when("FDDQ_enabled", {
    is: true,
    then: Yup.string().required().min(4, "Too short id"),
  }),
  FTDQ_enabled: Yup.boolean().required(),
  FTDQ_type: Yup.string().when("FTDQ_enabled", {
    is: true,
    then: Yup.string().required().min(1),
  }),
  FTDQ_id: Yup.string().when("FTDQ_enabled", {
    is: true,
    then: Yup.string().required().min(4, "Too short id"),
  }),
});

const PlaceConfigForm = (props) => {
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={props.initialValues}
      onSubmit={props.onSubmit}
      enableReinitialize={props.enableReinit}
    >
      {({ values, handleSubmit, isSubmitting, initialValues }) => (
        <Fragment>
          <ValidationErrorViewer messages={props.serverErrors} />
          <form onSubmit={handleSubmit} className={ss.form}>
            <ValidatedTextField
              name="name"
              type="input"
              label="Lake name"
              className={ss.input}
              fullWidth
            />
            <ValidatedTextField
              name="description"
              type="input"
              label="Description"
              className={ss.input}
              fullWidth
            />
            <ValidatedTextField
              name="url"
              type="input"
              label="URL (optional)"
              className={ss.input}
              fullWidth
            />
            <ValidatedTextField
              name="owner"
              type="input"
              label="Owner (optional)"
              className={ss.input}
              fullWidth
            />
            <ValidatedTextField
              name="lat"
              type="number"
              label="Latitude"
              placeholder="0.0"
              className={ss.input}
              fullWidth
            />
            <ValidatedTextField
              name="lng"
              type="number"
              label="Longitude"
              placeholder="0.0"
              className={ss.input}
              fullWidth
            />
            <ValidatedTextField
              name="depth"
              type="number"
              label="Sensor Depth"
              placeholder="1.0"
              className={ss.input}
              fullWidth
            />
            <SwitchWithLabel
              name="LDDQ_enabled"
              type="checkbox"
              label="Enable sensor-based live data"
            />
            <LDDQ
              valType="LDDQ_type"
              valId="LDDQ_id"
              disabled={!values.LDDQ_enabled}
            />
            <SwitchWithLabel
              name="FDDQ_enabled"
              type="checkbox"
              label="Enable model based realtime and forecast data"
            />
            <FDQ
              valType="FDDQ_type"
              valId="FDDQ_id"
              valFromDay="FDDQ_fromDay"
              valToDay="FDDQ_toDay"
              disabled={!values.FDDQ_enabled}
            />
            <SwitchWithLabel
              name="FTDQ_enabled"
              type="checkbox"
              label="Enable table view of model based realtime and forecast data"
            />
            <FDQ
              valType="FTDQ_type"
              valId="FTDQ_id"
              valFromDay="FTDQ_fromDay"
              valToDay="FTDQ_toDay"
              disabled={!values.FTDQ_enabled}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting || _.isEqual(values, initialValues)}
            >
              {props.label}
            </Button>
          </form>
        </Fragment>
      )}
    </Formik>
  );
};

export default PlaceConfigForm;

export const getInitValues = () => {
  return {
    name: "",
    description: "",
    url: "",
    lat: "",
    lng: "",
    depth: "",
    owner: "",
    LDDQ_enabled: false,
    LDDQ_type: "",
    LDDQ_id: "",
    FDDQ_enabled: false,
    FDDQ_type: "",
    FDDQ_id: "",
    FDDQ_fromDay: 0,
    FDDQ_toDay: 6,
    FTDQ_enabled: false,
    FTDQ_type: "",
    FTDQ_id: "",
    FTDQ_fromDay: 0,
    FTDQ_toDay: 6,
  };
};
