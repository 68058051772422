import React from "react";
import HeaderDrawerFrame from "../../../Base/Frames/HeaderDrawerFrame/HeaderDrawerFrame";
import PageView from "../../../Base/Frames/PageView/PageView";
import CreateVersionContainer from "./CreateVersion/CreateVersionContainer";
import VersionsListContainer from "./VersionsList/VersionsListContainer";

const AppVersionPage = (props) => {
  return (
    <HeaderDrawerFrame persistent={true}>
      <PageView>
        <CreateVersionContainer />
        <VersionsListContainer />
      </PageView>
    </HeaderDrawerFrame>
  );
};

export default AppVersionPage;
