import React, { useState } from "react";
import HeaderDrawerFrame from "../../../Base/Frames/HeaderDrawerFrame/HeaderDrawerFrame";
import PageView from "../../../Base/Frames/PageView/PageView";
import CreatePostContainer from "./CreatePost/CreatePostContainer";
import PostListContainer from "./PostList/PostListContainer";
import { useParams } from "react-router";
import NavigationControls from "../../../Base/Frames/NavigationControls/NavigationControls";

const PostPageContainer = (props) => {
  const { placeId } = useParams();
  const [post, setPost] = useState(null);

  const onCopyPost = (post) => {
    if (
      window.confirm(
        "Sure you want to copy this post, this will overwrite the current input in 'create post' form?"
      )
    ) {
      setPost(post);
    }
  };

  const onClearPost = () => {
    setPost(null);
  };

  return (
    <HeaderDrawerFrame persistent={true}>
      <PageView>
        <NavigationControls />
        <CreatePostContainer
          placeId={placeId}
          copiedPost={post}
          onClearPost={onClearPost}
        />
        <PostListContainer placeId={placeId} onCopyPost={onCopyPost} />
      </PageView>
    </HeaderDrawerFrame>
  );
};

export default PostPageContainer;
