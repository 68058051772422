import React, { Fragment } from "react";

//Css
import ss from "../PlaceConfigForm.module.css";

//Components
import ValidatedSelect from "../../../../../UI/Form/ValidatedSelect/ValidatedSelect";
import ValidatedTextField from "../../../../../UI/Form/ValidatedTextField/ValidatedTextField";
import { MenuItem } from "@material-ui/core";

const FDQ = (props) => {
  return (
    <Fragment>
      <div className={ss.selectContainer}>
        <ValidatedSelect
          name={props.valType}
          label="Device Type"
          type="select"
          className={ss.select}
          disabled={props.disabled}
        >
          <MenuItem value="MODELS">MODELS</MenuItem>
        </ValidatedSelect>
      </div>
      <ValidatedTextField
        name={props.valId}
        type="input"
        label="ID"
        placeholder="Model ID"
        className={ss.input}
        fullWidth
        disabled={props.disabled}
      />
      <div className={ss.daysContainer}>
        <div className={ss.selectContainer}>
          <ValidatedSelect
            name={props.valFromDay}
            label="From Day"
            type="select"
            className={ss.select}
            disabled={props.disabled}
          >
            <MenuItem value={0}>0</MenuItem>
            <MenuItem value={1}>1</MenuItem>
          </ValidatedSelect>
        </div>
        <div className={ss.selectContainer}>
          <ValidatedSelect
            name={props.valToDay}
            label="To Day"
            type="select"
            className={ss.select}
            disabled={props.disabled}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={6}>6</MenuItem>
          </ValidatedSelect>
        </div>
      </div>
    </Fragment>
  );
};

export default FDQ;
