import React from "react";

//Components
import { List } from "@material-ui/core";
import ProtectedNavLink from "./ProtectedNavLink/ProtectedNavLink";
import {
  P_HOME,
  P_MOBILEAPP_CONFIGURE,
  P_MOBILEAPP_PLACES,
  P_USERS,
  P_SM,
  P_MOBILEAPP_VERSION,
} from "../../../../../models/user-access/UserAccess";
import ProtectedSubList from "./ProtectedSubList/ProtectedSubList";
const SideDrawerNav = (props) => {
  return (
    <List>
      <ProtectedNavLink path={P_HOME} name="Overview" icon="APPS" />
      <ProtectedSubList title="Mobile App" titleIcon="MOBILE">
        <ProtectedNavLink
          path={P_MOBILEAPP_PLACES}
          name="Lakes"
          icon="EDIT_PLACE"
          nested
        />
        <ProtectedNavLink
          path={P_MOBILEAPP_CONFIGURE}
          name="Configure"
          icon="SETTINGS"
          nested
        />
        <ProtectedNavLink
          path={P_MOBILEAPP_VERSION}
          name="App Versions"
          icon="HISTORY"
          nested
        />
      </ProtectedSubList>
      <ProtectedNavLink path={P_USERS} name="Users" icon="USERS" />
      <ProtectedNavLink path={P_SM} name="System Monitor" icon="MONITOR" />
    </List>
  );
};

export default SideDrawerNav;
