import React from "react";
import { Switch } from "react-router-dom";
import GuardedRoute from "../../PageRouter/GuardedRoute/GuardedRoute";
import {
  P_MOBILEAPP_CONFIGURE,
  P_MOBILEAPP_CONFIGURE_ID,
  P_MOBILEAPP_PLACES,
  P_MOBILEAPP_PLACES_INFO_ID,
  P_MOBILEAPP_PLACES_POSTS_EDIT_ID,
  P_MOBILEAPP_PLACES_POSTS_ID,
  P_MOBILEAPP_VERSION,
} from "../../../models/user-access/UserAccess";
//Pages

import InfoPage from "./InfoPage/InfoPage";
import ConfigurePage from "./ConfigurePage/ConfigurePage";
import EditPlacePageContainer from "./EditPlacePage/EditPlacePageContainer";
import OverviewPageContainer from "./OverviewPage/OverviewPageContainer";
import PostPageContainer from "./PostPage/PostPageContainer";
import EditPostContainer from "./PostPage/EditPost/EditPostContainer";
import AppVersionPage from "./AppVersion/AppVersionPage";
import { SearchPlacesContextProvider } from "../../../context/MobilePlacesSearch/search-places-context";

const SubPagesRouter = () => {
  return (
    <SearchPlacesContextProvider>
      <Switch>
        <GuardedRoute path={P_MOBILEAPP_PLACES} exact>
          <OverviewPageContainer />
        </GuardedRoute>
        <GuardedRoute path={P_MOBILEAPP_PLACES_INFO_ID} exact>
          <InfoPage />
        </GuardedRoute>
        <GuardedRoute path={P_MOBILEAPP_CONFIGURE} exact>
          <ConfigurePage />
        </GuardedRoute>
        <GuardedRoute path={P_MOBILEAPP_CONFIGURE_ID}>
          <EditPlacePageContainer />
        </GuardedRoute>
        <GuardedRoute path={P_MOBILEAPP_PLACES_POSTS_ID} exact>
          <PostPageContainer />
        </GuardedRoute>
        <GuardedRoute path={P_MOBILEAPP_PLACES_POSTS_EDIT_ID} exact>
          <EditPostContainer />
        </GuardedRoute>
        <GuardedRoute path={P_MOBILEAPP_VERSION} exact>
          <AppVersionPage />
        </GuardedRoute>
      </Switch>
    </SearchPlacesContextProvider>
  );
};

export default SubPagesRouter;
