import React, { Fragment } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import _ from "lodash";

//css
import ss from "./VersionForm.module.css";

//Components
import ValidationErrorViewer from "../../../../../UI/ValidationErrorViewer/ValidationErrorViewer";
import ValidatedTextField from "../../../../../UI/Form/ValidatedTextField/ValidatedTextField";
import SwitchWithLabel from "../../../../../UI/Form/SwitchWithLabel/SwitchWithLabel";
import { Button } from "@material-ui/core";

const validationSchema = Yup.object().shape({
  version: Yup.string()
    .required()
    .matches(/[0-9]+.[0-9]+.[0-9]+/, "must have the form x.x.x"),
  description_DA: Yup.string().required().min(5, "Too short"),
  description_EN: Yup.string().required().min(5, "Too short"),
  expoUpdate: Yup.boolean().required(),
  forceUpdate: Yup.boolean().required(),
});

const VersionForm = (props) => {
  return (
    <Formik
      initialValues={props.initialValues}
      validationSchema={validationSchema}
      onSubmit={props.onSubmit}
      enableReinitialize
    >
      {({ handleSubmit, isSubmitting, values, initialValues }) => (
        <Fragment>
          <ValidationErrorViewer messages={props.serverErrors} />
          <form onSubmit={handleSubmit} className={ss.form}>
            <ValidatedTextField
              name="version"
              type="input"
              label="Version"
              className={ss.input}
              fullWidth
            />
            <ValidatedTextField
              type="input"
              name="description_DA"
              label="Description DA"
              className={ss.input}
              fullWidth
              rows={3}
              multiline
              variant="outlined"
            />
            <ValidatedTextField
              type="input"
              name="description_EN"
              label="Description EN"
              className={ss.input}
              fullWidth
              rows={3}
              multiline
              variant="outlined"
            />
            <SwitchWithLabel
              name="expoUpdate"
              type="checkbox"
              label="Update with expo OTA"
            />
            <SwitchWithLabel
              name="forceUpdate"
              type="checkbox"
              label="Force user to update"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting || _.isEqual(values, initialValues)}
            >
              {props.label}
            </Button>
          </form>
        </Fragment>
      )}
    </Formik>
  );
};

export default VersionForm;

export const getInitialValues = () => {
  return {
    version: "",
    description_DA: "",
    description_EN: "",
    expoUpdate: true,
    forceUpdate: false,
  };
};
