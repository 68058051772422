import React from "react";
import { useHistory } from "react-router";
import useMangedMutation from "../../../../../hooks/useManagedMutation";
import {
  MUTATION_DELETE_PLACE,
  QUERY_ALL_PLACES,
} from "../../../../../network/gql/mobile-app-service";

//Components
import Section from "../../../../UI/Section/Section";
import DeletePlaceForm from "./Layout/DeletePlaceForm";

const DeletePlaceContainer = (props) => {
  const history = useHistory();
  const [deletePlace, { serverErrors }] = useMangedMutation(
    MUTATION_DELETE_PLACE,
    { refetchQueries: [{ query: QUERY_ALL_PLACES }] }
  );
  const onDelete = async (values, actions) => {
    try {
      await deletePlace({
        variables: { id: props.placeId, name: values.name },
      });
      actions.setSubmitting(false);
      actions.resetForm();
      history.replace("/mobile-app/configure");
    } catch (error) {
      actions.setSubmitting(false);
    }
  };

  return (
    <Section title="Delete Place">
      <DeletePlaceForm
        initialValues={{ name: "" }}
        onSubmit={onDelete}
        serverErrors={serverErrors}
      />
    </Section>
  );
};

export default DeletePlaceContainer;
