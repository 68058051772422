import React, { Fragment } from "react";
import { Formik } from "formik";
import * as Yup from "yup";

//CSS
import ss from "./DeletePlaceForm.module.css";

//Components
import ValidatedTextField from "../../../../../UI/Form/ValidatedTextField/ValidatedTextField";
import ValidationErrorViewer from "../../../../../UI/ValidationErrorViewer/ValidationErrorViewer";
import WarningButton from "../../../../../UI/WarningButton/WarningButton";

const validationSchema = Yup.object().shape({
  name: Yup.string().min(4, "Too short"),
});

const DeletePlaceForm = (props) => {
  return (
    <Formik
      initialValues={props.initialValues}
      onSubmit={props.onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ handleSubmit, isSubmitting }) => (
        <Fragment>
          <ValidationErrorViewer messages={props.serverErrors} />
          <form onSubmit={handleSubmit} className={ss.form}>
            <ValidatedTextField
              type="input"
              name="name"
              label="Place name"
              fullWidth
              className={ss.input}
            />
            <WarningButton
              type="submit"
              fullWidth
              variant="contained"
              disabled={isSubmitting}
            >
              Delete
            </WarningButton>
          </form>
        </Fragment>
      )}
    </Formik>
  );
};

export default DeletePlaceForm;
