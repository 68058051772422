import React from "react";
import * as _ from "lodash";
import SwimSpotCard from "./SwimSpotCard/SwimSpotCard";
//css
import ss from "./CardPlacesList.module.css";

const CardPlacesList = (props) => {
  const placesWithTemp = props.places.filter((p) => p.liveDayDataQuery != null);
  const placesWithoutTemp = props.places.filter(
    (p) => p.liveDayDataQuery === null
  );

  const sortedPWT = _.orderBy(placesWithTemp, "name", "asc");
  const sortedPWOT = _.orderBy(placesWithoutTemp, "name", "asc");

  const sortedPlaces = [...sortedPWT, ...sortedPWOT];
  return (
    <div className={ss.container}>
      {sortedPlaces.map((p) => (
        <SwimSpotCard
          key={p.id}
          name={p.name}
          description={p.description}
          imagePath={p.imagePath}
          queryInfo={p.liveDayDataQuery}
        />
      ))}
    </div>
  );
};

export default CardPlacesList;
