import React from "react";
import ServiceCard from "./ServiceCard/ServcieCard";

//css
import ss from "./Services.module.css";

//Icons
import MobileFriendlyIcon from "@material-ui/icons/MobileFriendly";
import TimelineIcon from "@material-ui/icons/Timeline";

const Services = (props) => {
  return (
    <div className={ss.container}>
      <ServiceCard
        backgroundColor="rgb(121, 203, 228)"
        color="white"
        label="Mobile App CMS"
        url="/mobile-app/places"
      >
        <MobileFriendlyIcon
          style={{ color: "white", width: "100%", height: "100%" }}
        />
      </ServiceCard>
      <ServiceCard
        backgroundColor="#3eb8ac"
        color="white"
        label="ASAP Forecast"
        link="https://asap-forecast.com/"
      >
        <TimelineIcon
          style={{ color: "white", width: "100%", height: "100%" }}
        />
      </ServiceCard>
    </div>
  );
};

export default Services;
