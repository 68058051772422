import React from "react";
import clsx from "clsx";
import { useHistory } from "react-router";

//css
import ss from "./ServiceCard.module.css";

//Icons
import { Typography } from "@material-ui/core";

const ServiceCard = (props) => {
  const history = useHistory();
  const onClick = () => {
    if (props.link) {
      window.open(props.link);
    } else if (props.url) {
      history.push(props.url);
    }
  };

  return (
    <div
      className={clsx(ss.card, ss.container)}
      style={{ backgroundColor: props.backgroundColor }}
      onClick={onClick}
    >
      <div className={ss.iconContainer}>{props.children}</div>
      <Typography variant="h5" style={{ color: props.color }}>
        {props.label}
      </Typography>
    </div>
  );
};

export default ServiceCard;
