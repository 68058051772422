import React from "react";
import ClearIcon from "@material-ui/icons/Clear";
import { TextField } from "@material-ui/core";
import ss from "./SearchBar.module.css";
import ToolTipIconButton from "../ToolTipIconButton/ToolTipIconButton";

const SearchBar = (props) => {
  return (
    <div className={ss.container}>
      <div className={ss.searchbar}>
        <TextField
          color={props.color}
          variant="standard"
          label="Search"
          className={ss.textField}
          fullWidth={true}
          value={props.value}
          onChange={props.onChange}
          disabled={props.disabled}
        />
        <ToolTipIconButton
          title="Clear search"
          onClick={props.onClear}
          disabled={props.disabled}
        >
          <ClearIcon />
        </ToolTipIconButton>
      </div>
    </div>
  );
};

export default SearchBar;
