import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import {
  P_HOME,
  P_LOGIN,
  P_MOBILEAPP,
  P_USERS,
  P_PROFILE,
  P_SM,
  P_SWIMSPOTS,
} from "../../models/user-access/UserAccess";
//Components
import GuardedRoute from "./GuardedRoute/GuardedRoute";

//Pages
import LandingPageContainer from "../Pages/LandingPage/LandingPageContainer";
import MobileSubPages from "../Pages/MobilePage/SubPages";
import LoginPage from "../Pages/LoginPage/LoginPage";
import ProfilePage from "../Pages/ProfilePage/ProfilePage";
import SubPagesRouter from "../Pages/UsersPage/SubPages";
import UnitSubPage from "../Pages/SystemMonitorPage/SubPages";
import NoAccessPageContainer from "../Pages/NoAccessPage/NoAccessPageContainer";
import SwimSpotsSubPages from "../Pages/SwimSpots/SubPages";

const PageRouter = () => {
  return (
    <Switch>
      <Route exact path={P_HOME}>
        <LandingPageContainer />
      </Route>
      <Route path={P_SWIMSPOTS}>
        <SwimSpotsSubPages />
      </Route>
      <GuardedRoute path={P_USERS}>
        <SubPagesRouter />
      </GuardedRoute>
      <GuardedRoute path={P_MOBILEAPP}>
        <MobileSubPages />
      </GuardedRoute>
      <Route path={P_LOGIN}>
        <LoginPage />
      </Route>
      <GuardedRoute path={P_PROFILE}>
        <ProfilePage />
      </GuardedRoute>
      <GuardedRoute path={P_SM}>
        <UnitSubPage />
      </GuardedRoute>
      <Route path="/noaccess">
        <NoAccessPageContainer />
      </Route>
      <Route path="*">
        <Redirect to={P_HOME} />
      </Route>
    </Switch>
  );
};

export default PageRouter;
