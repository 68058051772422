import React, { useState, useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import { client } from "../../network/ApolloClient/ApolloClient";
import { useHistory } from "react-router-dom";

const ACCESS_TOKEN = "accessToken";
const REFRESH_TOKEN = "refreshToken";

const AuthContext = React.createContext({
  isLoggedIn: false,
  user: null,
  login: (accessToken, refreshToken, user) => {},
  logout: () => {},
});

export const AuthContextProvider = (props) => {
  const history = useHistory();
  const [logout] = useMutation(LOGOUT);
  const [user, setUser] = useState(null);

  useEffect(() => {
    async function _fetch() {
      try {
        if (getRefreshToken() === "") return;
        const data = await client.mutate({
          mutation: AUTOLOGIN,
          variables: { refreshToken: getRefreshToken() },
        });

        const { token, user } = data.data.refreshAccessToken;
        setAccessToken(token);
        setUser(user);

        if (history.action === "PUSH") {
          //Not sure if this is the best way... but i works
          history.goBack();
        }
      } catch (error) {
        clearTokens();
        setUser(null);
      }
    }
    _fetch();
  }, [history]);

  const onLogin = (accessToken, refreshToken, user) => {
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
    setUser(user);
  };

  const onLogout = async () => {
    try {
      await logout({ variables: { refreshToken: getRefreshToken() } });
    } catch (error) {
      console.log(error);
    }
    clearTokens();
    setUser(null);
    client.cache.reset();
  };

  const contextValue = {
    isLoggedIn: user !== null,
    user: user,
    login: onLogin,
    logout: onLogout,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN) || "";
};

export const setAccessToken = (accessToken) => {
  localStorage.setItem(ACCESS_TOKEN, accessToken);
};

export const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN) || "";
};

export const setRefreshToken = (refreshToken) => {
  localStorage.setItem(REFRESH_TOKEN, refreshToken);
};

export const clearTokens = () => {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
};

const LOGOUT = gql`
  mutation logout($refreshToken: String!) {
    logout(refreshToken: $refreshToken)
  }
`;

const AUTOLOGIN = gql`
  mutation refreshToken($refreshToken: String!) {
    refreshAccessToken(refreshToken: $refreshToken) {
      token
      user {
        firstName
        lastName
        email
        permissions
      }
    }
  }
`;
