import React, { Fragment } from "react";
import { useQuery } from "@apollo/client";
import {
  MUTATION_DELETE_EDITOR,
  QUERY_PLACE_EDITORS,
} from "../../../../../../../network/gql/mobile-app-service";
import EditorRow from "./EditorRow";
import GQLQueryErrorHandler from "../../../../../../UI/GQLQueryErrorHandler/GQLQueryErrorHandler";
import useManagedMutation from "../../../../../../../hooks/useManagedMutation";
import ValidationErrorViewer from "../../../../../../UI/ValidationErrorViewer/ValidationErrorViewer";

const EditorsList = (props) => {
  const { data, loading, error } = useQuery(QUERY_PLACE_EDITORS, {
    variables: { placeId: props.placeId },
  });
  const [deleteEditor, { serverErrors }] = useManagedMutation(
    MUTATION_DELETE_EDITOR,
    {
      refetchQueries: [
        { query: QUERY_PLACE_EDITORS, variables: { placeId: props.placeId } },
      ],
    }
  );

  const onDelete = async (userId) => {
    deleteEditor({ variables: { userId, placeId: props.placeId } });
  };

  if (loading) return <p>Loading...</p>;

  return (
    <Fragment>
      <GQLQueryErrorHandler error={error} />
      <ValidationErrorViewer messages={serverErrors} />
      <div>
        {data &&
          data.placeEditors.map((user) => (
            <EditorRow
              key={user.userId}
              userId={user.userId}
              onDelete={onDelete.bind(null, user.userId)}
            />
          ))}
      </div>
    </Fragment>
  );
};
export default EditorsList;
