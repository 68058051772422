import { v4 as uuidv4 } from "uuid";
import { HEADLINE, HYPERLINK, BODYTEXT, ICON_STATEMENT } from "./constants";

export const denormalize = (values) => {
  let denom = [];

  denom.push(denomHeadline(values.headline_1));
  denom.push(denomBody(values.body_1));
  denom = [...denom, ...denomStatements(values.statements)];

  if (values.body_2.length > 0) {
    denom.push(denomBody(values.body_2));
  }
  denom = [...denom, ...denomHyperlinks(values.hyperlinks)];

  return denom;
};

const denomHeadline = (title) => {
  return {
    id: uuidv4(),
    type: HEADLINE,
    payload: {
      title,
    },
  };
};

const denomBody = (text) => {
  return {
    id: uuidv4(),
    type: BODYTEXT,
    payload: {
      text,
    },
  };
};

const denomStatements = (statements) => {
  return statements.map((s) => {
    return {
      id: s.id,
      type: ICON_STATEMENT,
      payload: {
        icon: s.icon,
        text: s.text,
      },
    };
  });
};

const denomHyperlinks = (hyperlinks) => {
  return hyperlinks.map((hl) => {
    return {
      id: hl.id,
      type: HYPERLINK,
      payload: {
        url: hl.url,
        text: hl.text,
      },
    };
  });
};

export const normalize = (denom) => {
  const norm = {
    headline_1: "",
    body_1: "",
    body_2: "",
    statements: [],
    hyperlinks: [],
  };

  let bodyCnt = 1;
  denom.forEach((el) => {
    switch (el.type) {
      case HEADLINE:
        norm.headline_1 = normHeadline(el);
        break;
      case BODYTEXT:
        norm[`body_${bodyCnt++}`] = normBody(el);
        break;
      case ICON_STATEMENT:
        norm.statements.push(normStatement(el));
        break;
      case HYPERLINK:
        norm.hyperlinks.push(normHyperlink(el));
        break;

      default:
        console.log(`${el.type} is not valid`);
    }
  });
  return norm;
};

const normHeadline = (data) => {
  return data.payload.title;
};

const normBody = (data) => {
  return data.payload.text;
};

const normStatement = (data) => {
  return {
    id: data.id,
    icon: data.payload.icon,
    text: data.payload.text,
  };
};

const normHyperlink = (data) => {
  return {
    id: data.id,
    url: data.payload.url,
    text: data.payload.text,
  };
};
