import clsx from "clsx";
import React from "react";

//CSS
import ss from "./Card.module.css";

const Card = (props) => {
  return (
    <div className={clsx([ss.container, props.className])}>
      {props.children}
    </div>
  );
};

export default Card;
