import { Typography } from "@material-ui/core";
import React from "react";

//CSS
import ss from "./BodyText.module.css";

const BodyText = (props) => {
  return (
    <div className={ss.container}>
      <Typography variant="body1">{props.text}</Typography>
    </div>
  );
};

export default BodyText;
