import React, { useState, useEffect, useCallback } from "react";
import moment from "moment-timezone";
import useManagedMutation from "../../../../../hooks/useManagedMutation";
import {
  MUTATION_DELETE_UNIT,
  QUERY_UNITS,
} from "../../../../../network/gql/system-monitor";
import { useHistory } from "react-router-dom";

import UnitCard from "./Layout/UnitCard/UnitCard";

const UnitCardContainer = (props) => {
  const history = useHistory();
  const [showLog, setShowLog] = useState(false);
  const [times, setTimes] = useState({});
  const [deleteUnit, { serverErrors }] = useManagedMutation(
    MUTATION_DELETE_UNIT,
    {
      refetchQueries: [
        {
          query: QUERY_UNITS,
        },
      ],
    }
  );
  const { unit } = props;

  const calcTimes = useCallback(() => {
    const t = {};
    t.lastSeen = minutesToNow(unit.lastSeen);
    t.nextScan = minutesFromNow(unit.nextCheck);
    t.lastScan = minutesToNow(unit.lastScan);
    return t;
  }, [unit]);

  useEffect(() => {
    const t = calcTimes();
    setTimes(t);

    const timer = setInterval(() => {
      const t = calcTimes();
      setTimes(t);
    }, 20 * 1000);

    return () => clearInterval(timer);
  }, [setTimes, unit, calcTimes]);

  const toggleShowLog = () => {
    setShowLog((state) => !state);
  };

  const onEdit = () => {
    history.push(`/system-monitor/${unit.id}`);
  };

  const onDelete = async () => {
    if (window.confirm(`Sure yoy want to delete: ${unit.info}?`)) {
      await deleteUnit({ variables: { id: unit.id } });
    }
  };

  return (
    <UnitCard
      id={unit.id}
      name={unit.info}
      lastSeen={times.lastSeen}
      state={unit.state}
      nextScan={times.nextScan}
      lastScan={times.lastScan}
      interval={unit.interval}
      delay={unit.delay}
      maxTimeOffline={unit.maxTimeOffline}
      showLog={showLog}
      onToggleShowLog={toggleShowLog}
      onEdit={onEdit}
      onDelete={onDelete}
      serverErrors={serverErrors ? serverErrors : []}
    />
  );
};

const minutesFromNow = (ts) => {
  if (ts === null) return null;
  return moment(ts).diff(moment(), "minutes");
};

const minutesToNow = (ts) => {
  if (ts === null) return null;
  return moment().diff(moment(ts), "minutes");
};

export default UnitCardContainer;
