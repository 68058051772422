import React from "react";

//CSS
import ss from "./Brand.module.css";

//Components
import Logo from "../../../../../assets/ASAP_logo.svg";
import { Typography } from "@material-ui/core";

const Brand = (props) => {
  return (
    <div className={ss.container}>
      <div className={ss.logoWrapper}>
        <img src={Logo} alt="Logo" className={ss.image} />
        <div>
          <Typography>WaterWebTools</Typography>
          <Typography variant="caption">
            Version: {process.env.REACT_APP_VERSION}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Brand;
