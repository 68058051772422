import React, { useContext } from "react";
import { useQuery, useMutation } from "@apollo/client";

//Components
import Section from "../../../../UI/Section/Section";
import PlaceConfigForm from "../../Layouts/PlaceConfig/PlaceConfigForm";
import {
  MUTATION_UPDATE_PLACE,
  QUERY_PLACE,
} from "../../../../../network/gql/mobile-app-service";
import GQLQueryErrorHandler from "../../../../UI/GQLQueryErrorHandler/GQLQueryErrorHandler";
import {
  denormalizePlace,
  normalizePlace,
} from "../../../../../models/mobile-app/create-place-formatter-model";
import { gqlErrorParser } from "../../../../UI/ValidationErrorViewer/ValidationErrorViewer";
import CritialErrorContext from "../../../../../context/critial-error/critial-error-context";

const ConfigurePlace = (props) => {
  const criticalErrorCtx = useContext(CritialErrorContext);
  const { data, loading, error } = useQuery(QUERY_PLACE, {
    variables: { id: props.placeId },
  });
  const [updatePlace, { error: errorUpdatePlace }] = useMutation(
    MUTATION_UPDATE_PLACE
  );

  const onUpdate = async (values, actions) => {
    try {
      const vars = normalizePlace(values);
      await updatePlace({ variables: { InputPlace: vars, id: props.placeId } });
      actions.setSubmitting(false);
      actions.resetForm();
    } catch (error) {
      if (error.networkError) {
        criticalErrorCtx.setError(error.networkError, "Unable to update place");
      }
      actions.setSubmitting(false);
    }
  };

  return (
    <Section title="Edit Place">
      <GQLQueryErrorHandler error={error} />
      {loading && <p>Loading...</p>}
      {data && (
        <PlaceConfigForm
          initialValues={denormalizePlace(data.place)}
          onSubmit={onUpdate}
          serverErrors={
            errorUpdatePlace && gqlErrorParser(errorUpdatePlace.graphQLErrors)
          }
          label="UPDATE"
          enableReinit={true}
        />
      )}
    </Section>
  );
};

export default ConfigurePlace;
