import React from "react";
import { useQuery } from "@apollo/client";

import Section from "../../../../UI/Section/Section";
import Table from "../../../../UI/Table/Table";
import VersionRow from "./Layout/VersionRow";
import {
  MUTATION_DELETE_APP_VERSION,
  QUERY_APP_VERSIONS,
} from "../../../../../network/gql/mobile-app-service";
import GQLQueryErrorHandler from "../../../../UI/GQLQueryErrorHandler/GQLQueryErrorHandler";
import useMangedMutation from "../../../../../hooks/useManagedMutation";

const VersionsListContainer = (props) => {
  const { data, error } = useQuery(QUERY_APP_VERSIONS, {
    variables: { latest: 10 },
  });
  const [deleteAppVersion] = useMangedMutation(MUTATION_DELETE_APP_VERSION, {
    refetchQueries: [
      {
        query: QUERY_APP_VERSIONS,
        variables: { latest: 10 },
      },
    ],
  });

  const onDelete = async (id) => {
    try {
      await deleteAppVersion({ variables: { id: id } });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Section title="Versions">
      <GQLQueryErrorHandler error={error} />
      <Table>
        {data &&
          data.appVersions.map((av) => (
            <VersionRow
              key={av.id}
              version={av.version}
              descriptionDA={av.description_DA}
              descriptionEN={av.description_EN}
              expoUpdate={av.expoUpdate}
              forcedUpdate={av.forceUpdate}
              onDelete={onDelete.bind(null, av.id)}
            />
          ))}
      </Table>
    </Section>
  );
};

export default VersionsListContainer;
