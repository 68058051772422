import React, { Fragment } from "react";
import { Formik } from "formik";
import moment from "moment-timezone";
import * as Yup from "yup";
import * as _ from "lodash";
//css
import ss from "./InfoPostForm.module.css";

//Components
import ValidatedTextField from "../../../../../UI/Form/ValidatedTextField/ValidatedTextField";
import DatePicker from "../../../../../UI/Form/DatePicker/DatePicker";
import StatementsArray, {
  getInitItem as getStatementInit,
} from "../../../../../UI/Form/StatementsArray/StatementsArray";
import { Button, MenuItem } from "@material-ui/core";
import ValidationErrorViewer from "../../../../../UI/ValidationErrorViewer/ValidationErrorViewer";
import {
  ICON_CHECK,
  ICON_CLOCK,
  ICON_DANGER,
  ICON_INFO,
  ICON_WARNING,
  ICON_PARKING,
  ICON_LOCATION,
  ICON_ACHIEVEMENT,
} from "../../../../../../constants/IconTypes";

import MenuItemWithIcon from "../../../../../UI/Form/StatementsArray/MenuItemWithIcon/MenuItemWithIcon";
/**
 * Validation
 */

const validationSchema = Yup.object().shape({
  date: Yup.date("Invalid date").required("Date is required"),
  statements: Yup.array().of(
    Yup.object({
      icon: Yup.string().required("Required"),
      text: Yup.string()
        .required("Required")
        .min(5, "Too short")
        .max(70, "Too long"),
    })
  ),
  description: Yup.string().required().max(1000, "Too long"),
});

const InfoPostForm = (props) => {
  return (
    <Formik
      initialValues={props.initialValues}
      validationSchema={validationSchema}
      onSubmit={props.onSubmit}
      enableReinitialize
    >
      {({ values, handleSubmit, isSubmitting, initialValues }) => (
        <Fragment>
          <ValidationErrorViewer messages={props.serverErrors} />
          <form onSubmit={handleSubmit} className={ss.form}>
            <DatePicker
              name="date"
              label="Post date"
              format="DD-MM-yyyy"
              maxDate={moment()}
              className={ss.input}
            />
            <StatementsArray arrayName="statements" items={values.statements}>
              <MenuItem value={ICON_ACHIEVEMENT}>
                <MenuItemWithIcon
                  iconType={ICON_ACHIEVEMENT}
                  text="Achievement"
                />
              </MenuItem>
              <MenuItem value={ICON_INFO}>
                <MenuItemWithIcon iconType={ICON_INFO} text="Information" />
              </MenuItem>
              <MenuItem value={ICON_CLOCK}>
                <MenuItemWithIcon iconType={ICON_CLOCK} text="Event time" />
              </MenuItem>
              <MenuItem value={ICON_PARKING}>
                <MenuItemWithIcon iconType={ICON_PARKING} text="Parking" />
              </MenuItem>
              <MenuItem value={ICON_LOCATION}>
                <MenuItemWithIcon iconType={ICON_LOCATION} text="Address" />
              </MenuItem>
              <MenuItem value={ICON_CHECK}>
                <MenuItemWithIcon iconType={ICON_CHECK} text="Good" />
              </MenuItem>
              <MenuItem value={ICON_WARNING}>
                <MenuItemWithIcon iconType={ICON_WARNING} text="Warning" />
              </MenuItem>
              <MenuItem value={ICON_DANGER}>
                <MenuItemWithIcon iconType={ICON_DANGER} text="Danger" />
              </MenuItem>
            </StatementsArray>
            <ValidatedTextField
              type="input"
              name="description"
              label="Description"
              className={ss.input}
              fullWidth
              rows={4}
              multiline
              variant="outlined"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting || _.isEqual(values, initialValues)}
            >
              {props.label}
            </Button>
          </form>
        </Fragment>
      )}
    </Formik>
  );
};

export const getInitialValues = () => {
  return {
    date: moment(),
    statements: [getStatementInit()],
    description: "",
  };
};

export default InfoPostForm;
