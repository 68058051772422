import React, { useEffect } from "react";
import { Typography } from "@material-ui/core";
import Card from "../../../../../UI/Card/Card";
import defaultPlaceImage from "../../../../../../assets/default-place-image.jpg";
import { client } from "../../../../../../network/ApolloClient/IOTApolloClient";
import moment from "moment-timezone";
import { useLazyQuery } from "@apollo/client";
import { QUERY_IOT_LIVE_TEMP } from "../../../../../../network/gql/iot-data-service";
import waveImg from "../../../../../../assets/wave.png";
//CSS
import ss from "./SwimSpotCard.module.css";

const SwimSpotCard = (props) => {
  let imageSrc = defaultPlaceImage;
  if (props.imagePath !== null) {
    imageSrc = `${process.env.REACT_APP_BACKEND_IMAGES_LOAD}/${props.imagePath}`;
  }

  const [getTemp, { data }] = useLazyQuery(QUERY_IOT_LIVE_TEMP, { client });

  useEffect(() => {
    if (!props.queryInfo) return;
    getTemp({
      variables: {
        type: props.queryInfo.type,
        id: props.queryInfo.id,
        timeNow: moment().unix(),
      },
    });
  }, [props.queryInfo, getTemp]);

  const tempStr = data ? data.temp.currentTemp.toFixed(1) : "--.-";

  return (
    <Card className={ss.card}>
      <img src={imageSrc} alt="Bathing place" className={ss.image} />
      <div className={ss.container}>
        <Typography variant="h4">{props.name}</Typography>
        <Typography variant="body1">{props.description}</Typography>
      </div>

      {props.queryInfo && (
        <div className={ss.tempContainer}>
          <div className={ss.tempText}>
            <Typography variant="h4">{tempStr}</Typography>
            <Typography variant="h6"> &deg;C</Typography>
          </div>
          <img src={waveImg} alt="Wave" className={ss.waveImage} />
        </div>
      )}
    </Card>
  );
};

export default SwimSpotCard;
