import React from "react";
import HeaderDrawerFrame from "../../../Base/Frames/HeaderDrawerFrame/HeaderDrawerFrame";
import PageView from "../../../Base/Frames/PageView/PageView";
import Places from "./Places/PlacesContainer";
import CreatePlace from "./CreatePlace/CreatePlaceContainer";

const ConfigurePage = (props) => {
  return (
    <HeaderDrawerFrame persistent={true}>
      <PageView>
        <CreatePlace />
        <Places />
      </PageView>
    </HeaderDrawerFrame>
  );
};

export default ConfigurePage;
