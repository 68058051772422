import React, { Fragment } from "react";

//Components
import CheckBox from "../CheckBox/CheckBox";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import { Typography } from "@material-ui/core";

const CheckList = (props) => {
  return (
    <FormControl>
      <FormLabel>{props.title}</FormLabel>
      <FormGroup>
        {props.list.map((item) => (
          <Fragment key={item.value}>
            <CheckBox
              type="checkbox"
              name={props.name}
              value={item.value}
              label={item.label}
              color="primary"
            />
            <Typography variant="caption">{item.description}</Typography>
          </Fragment>
        ))}
      </FormGroup>
    </FormControl>
  );
};

export default CheckList;
