import React from "react";
import { Switch } from "react-router-dom";
import GuardedRoute from "../../PageRouter/GuardedRoute/GuardedRoute";
import { P_USERS, P_USERS_ID } from "../../../models/user-access/UserAccess";
//Pages
import UsersPage from "./UsersPage/UsersPage";
import UserPage from "./UserPage/UserPage";

const SubPagesRouter = () => {
  return (
    <Switch>
      <GuardedRoute path={P_USERS_ID}>
        <UserPage />
      </GuardedRoute>
      <GuardedRoute path={P_USERS}>
        <UsersPage />
      </GuardedRoute>
    </Switch>
  );
};

export default SubPagesRouter;
