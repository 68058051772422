import React from "react";

//css
import ss from "../BarGraph.module.css";

import { Typography } from "@material-ui/core";

const Headline = (props) => {
  return (
    <div className={ss.headline}>
      <Typography variant="body1">{props.label}</Typography>
      <Typography variant="body1">{`${props.value} ${props.unit}`}</Typography>
    </div>
  );
};

export default Headline;
