import { gql } from "@apollo/client";

export const MUTATION_ADD_UNIT = gql`
  mutation addUnit($unit: InputUnit!) {
    addUnit(unit: $unit) {
      id
      unitId
      type
      datasource
      interval
      delay
      maxTimeOffline
      paused
      lastSeen
      nextCheck
      lastScan
      state
    }
  }
`;

export const QUERY_UNITS = gql`
  query units {
    units {
      id
      info
      unitId
      type
      datasource
      interval
      delay
      maxTimeOffline
      paused
      lastSeen
      nextCheck
      lastScan
      state
    }
  }
`;

export const MUTATION_DELETE_UNIT = gql`
  mutation deleteUnit($id: String!) {
    deleteUnit(id: $id) {
      id
    }
  }
`;

export const QUERY_UNIT = gql`
  query unit($id: String!) {
    unit(id: $id) {
      id
      info
      unitId
      type
      datasource
      interval
      delay
      maxTimeOffline
    }
  }
`;

export const MUTATION_UPDATE_UNIT = gql`
  mutation updateUnit($unit: InputUnit!, $id: String!) {
    updateUnit(unit: $unit, id: $id) {
      id
      info
      unitId
      type
      datasource
      interval
      delay
      maxTimeOffline
      paused
      lastSeen
      nextCheck
      lastScan
      state
    }
  }
`;

export const QUERY_STATE_LOG = gql`
  query statesLog($id: String!) {
    stateLogs(id: $id, max: 50) {
      timestamp
      state
    }
  }
`;
