import React from "react";

//Components
import HeaderDrawerFrame from "../../Base/Frames/HeaderDrawerFrame/HeaderDrawerFrame";
import HeaderImages from "./Layout/HeaderImages/HeaderImages";
import PageView from "../../Base/Frames/PageView/PageView";
import Services from "./Layout/Services/Services";

const LandingPageContainer = () => {
  return (
    <HeaderDrawerFrame persistent={true}>
      <HeaderImages />
      <PageView>
        <Services />
      </PageView>
    </HeaderDrawerFrame>
  );
};

export default LandingPageContainer;
