import { Divider } from "@material-ui/core";
import React from "react";
import BarGraph from "../Layout/BarGraph/BarGraph";
import BodyText from "../Layout/BodyText/BodyText";
import Statement from "../Layout/Statement/Statement";
import Wrapper from "../Layout/Wrapper/Wrapper";

const WQPostContainer = (props) => {
  return (
    <Wrapper>
      {props.statements &&
        props.statements.map((st) => (
          <Statement
            key={st.id}
            icon={st.payload.icon}
            text={st.payload.text}
          />
        ))}
      <Divider />
      {props.datapoints &&
        props.datapoints.map((dp) => (
          <BarGraph
            key={dp.id}
            rangeMin={dp.payload.rangeMin}
            rangeMax={dp.payload.rangeMax}
            value={dp.payload.value}
            label={dp.payload.label}
            unit={dp.payload.unit}
            lowLimit={dp.payload.limitMin}
            highLimit={dp.payload.limitMax}
            status={dp.payload.quality}
          />
        ))}
      <Divider />
      <BodyText text={props.description.payload.text} />
    </Wrapper>
  );
};

export default WQPostContainer;
