import React from "react";
import {
  WATERQUALITY,
  INFORMATION,
} from "../../../../../../../constants/PostTypes";

//CSS
import ss from "./Controls.module.css";

//Components
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { Button } from "@material-ui/core";

const Controls = (props) => {
  return (
    <div className={ss.container}>
      <FormControl className={ss.select}>
        <InputLabel id="demo-simple-select-label">Post Type</InputLabel>
        <Select
          fullWidth
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={props.type}
          onChange={props.onTypeChange}
        >
          <MenuItem value={WATERQUALITY}>Waterquality</MenuItem>
          <MenuItem value={INFORMATION}>Information</MenuItem>
        </Select>
      </FormControl>
      <Button
        onClick={props.onClearAll}
        variant="contained"
        color="secondary"
        disabled={props.disableRemoveBtn}
      >
        Remove copied
      </Button>
    </div>
  );
};

export default Controls;
