import React from "react";

//CSS
import ss from "./SideDrawer.module.css";
import { makeStyles } from "@material-ui/core/styles";

//Components
import Drawer from "@material-ui/core/Drawer";
import SideDrawerNav from "./SideDrawerNav/SideDrawerNav";
import { IconButton, Divider } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import clsx from "clsx";
import Brand from "./Brand/Brand";

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  control: {
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
}));

const SideDrawer = (props) => {
  const styles = useStyles();
  return (
    <Drawer
      anchor="left"
      className={ss.sideDrawer}
      classes={{ paper: ss.sideDrawerPaper }}
      variant={props.variant}
      open={props.drawerOpen}
      onClose={props.onCloseDrawer}
    >
      <div className={clsx([styles.toolbar, ss.toolBar])} />
      <div className={clsx([styles.control, ss.drawerControl])}>
        <IconButton onClick={props.onCloseDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <Brand />
      <Divider />
      <SideDrawerNav />
    </Drawer>
  );
};

export default SideDrawer;
