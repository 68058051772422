import React from "react";
import { useField } from "formik";

//Components
import { Select, InputLabel, FormControl } from "@material-ui/core";
import { FormHelperText } from "@material-ui/core";

const ValidatedSelect = (props) => {
  const [field, meta] = useField(props);
  const hasError = meta.error && meta.touched;
  const errorText = hasError ? meta.error : "";
  return (
    <FormControl error={hasError} className={props.className}>
      <InputLabel id="x">{props.label}</InputLabel>
      <Select labelId="x" {...field} {...props}>
        {props.children}
      </Select>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

export default ValidatedSelect;
