import React from "react";
import useMangedMutation from "../../../../../hooks/useManagedMutation";
import {
  MUTATION_ADD_UNIT,
  QUERY_UNITS,
} from "../../../../../network/gql/system-monitor";

//Components
import Section from "../../../../UI/Section/Section";
import UnitForm, { getInitValues } from "../../UnitForm/UnitForm";

const CreateUnitContainer = (props) => {
  const [addUnit, { serverErrors }] = useMangedMutation(MUTATION_ADD_UNIT, {
    refetchQueries: [
      {
        query: QUERY_UNITS,
      },
    ],
  });

  const onSubmit = async (values, actions) => {
    try {
      const {
        unitId,
        type,
        datasource,
        interval,
        delay,
        maxTimeOffline,
        info,
      } = values;
      await addUnit({
        variables: {
          unit: {
            unitId,
            type,
            datasource,
            interval,
            delay,
            maxTimeOffline,
            info,
          },
        },
      });

      actions.resetForm();
    } catch (error) {
      console.log(error);
    }
    actions.setSubmitting(false);
  };

  return (
    <Section title="Add Unit">
      <UnitForm
        onSubmit={onSubmit}
        initialValues={getInitValues()}
        serverErrors={serverErrors ? serverErrors : []}
        label="ADD"
      />
    </Section>
  );
};

export default CreateUnitContainer;
