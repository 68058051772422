import React, { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router";
import * as _ from "lodash";

//Components
import Section from "../../../../UI/Section/Section";
import GQLQueryErrorHandler from "../../../../UI/GQLQueryErrorHandler/GQLQueryErrorHandler";
import { QUERY_POSTS } from "../../../../../network/gql/mobile-app-service";
import Table from "../../../../UI/Table/Table";
import TableRowContainer from "./TableRow/TableRowContainer";

const PostListContainer = (props) => {
  const { placeId } = useParams();
  const [queryPosts, { data, error }] = useLazyQuery(QUERY_POSTS, {
    variables: {
      placeId: props.placeId,
      language: "DA",
    },
  });

  useEffect(() => {
    queryPosts();
  }, [queryPosts]);

  const sortedPost = data ? _.orderBy(data.posts, ["postDate"], "desc") : [];

  return (
    <Section title="Posts">
      <GQLQueryErrorHandler error={error} />
      <Table>
        {data &&
          sortedPost.map((p) => (
            <TableRowContainer
              key={p.id}
              data={p}
              placeId={placeId}
              elements={p.elements}
              onCopyPost={props.onCopyPost.bind(null, p)}
            />
          ))}
      </Table>
    </Section>
  );
};

export default PostListContainer;
