import { Typography } from "@material-ui/core";
import React from "react";

import ss from "./Body.module.css";

const HeadlineBox = (props) => {
  return (
    <div className={ss.headlineBox}>
      <Typography>{`${props.title}:`}</Typography>
    </div>
  );
};

const Body = (props) => {
  return (
    <div className={ss.container}>
      <HeadlineBox title="Danish" />
      <Typography variant="body2">{props.descriptionDA}</Typography>
      <HeadlineBox title="English" />
      <Typography variant="body2">{props.descriptionEN}</Typography>
      <Typography>{`Expo update?   ${props.expoUpdate}`}</Typography>
      <Typography>{`Forced update?   ${props.forcedUpdate}`}</Typography>
    </div>
  );
};

export default Body;
