import React from "react";
import HeaderDrawerFrame from "../../../Base/Frames/HeaderDrawerFrame/HeaderDrawerFrame";
import PageView from "../../../Base/Frames/PageView/PageView";
import { useParams } from "react-router";
import EditUnitContainer from "./EditUnit/EditUnitContainer";
import NavigationControls from "../../../Base/Frames/NavigationControls/NavigationControls";

const EditUnitPage = () => {
  const { id } = useParams();

  return (
    <HeaderDrawerFrame persistent={true}>
      <PageView>
        <NavigationControls />
        <EditUnitContainer id={id} />
      </PageView>
    </HeaderDrawerFrame>
  );
};

export default EditUnitPage;
