import { Divider } from "@material-ui/core";
import React from "react";
import BodyText from "../Layout/BodyText/BodyText";
import Statement from "../Layout/Statement/Statement";
import Wrapper from "../Layout/Wrapper/Wrapper";

const InfoPostContainer = (props) => {
  return (
    <Wrapper>
      {props.statements &&
        props.statements.map((st) => (
          <Statement
            key={st.id}
            icon={st.payload.icon}
            text={st.payload.text}
          />
        ))}
      <Divider />
      <BodyText text={props.description.payload.text} />
    </Wrapper>
  );
};

export default InfoPostContainer;
