import React from "react";
import useMangedMutation from "../../../../../../hooks/useManagedMutation";
import { useHistory } from "react-router";

//Components
import Section from "../../../../../UI/Section/Section";
import DeletePostForm from "./DeletePostForm/DeletePostForm";
import {
  MUTATION_DELETE_POST,
  QUERY_POSTS,
} from "../../../../../../network/gql/mobile-app-service";

const DeletePostContainer = (props) => {
  const history = useHistory();
  const [deletePost, { serverErrors }] = useMangedMutation(
    MUTATION_DELETE_POST,
    {
      refetchQueries: [
        {
          query: QUERY_POSTS,
          variables: { placeId: props.placeId, language: "DA" },
        },
      ],
    }
  );

  const onDelete = async (values, actions) => {
    try {
      await deletePost({ variables: { id: props.id } });
      actions.resetForm();
      actions.setSubmitting(false);
      history.replace(`/mobile-app/places/${props.placeId}/posts`);
    } catch (error) {
      console.log(error);
      actions.setSubmitting(false);
    }
  };

  return (
    <Section title="Delete Post">
      <DeletePostForm onDelete={onDelete} serverErrors={serverErrors} />
    </Section>
  );
};

export default DeletePostContainer;
