import React from "react";

//Css
import ss from "./StatementLayout.module.css";

//Components
import ValidatedTextField from "../../ValidatedTextField/ValidatedTextField";
import ValidatedSelect from "../../ValidatedSelect/ValidatedSelect";
import ToolTipIconButton from "../../../ToolTipIconButton/ToolTipIconButton";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

const StatementLayout = (props) => {
  return (
    <div className={ss.container}>
      <div className={ss.inputContainer}>
        <ValidatedSelect
          label={props.label ? props.label : "Status"}
          name={`${props.arrayName}.${props.index}.icon`}
          type="select"
          className={ss.select}
        >
          {props.children}
        </ValidatedSelect>

        <ValidatedTextField
          name={`${props.arrayName}.${props.index}.text`}
          type="input"
          label="Text"
          fullWidth
        />
      </div>
      <div className={ss.actionContainer}>
        <ToolTipIconButton
          title="Move down"
          onClick={props.onMoveUp}
          disabled={props.isLast}
        >
          <ArrowDownwardIcon />
        </ToolTipIconButton>
        <ToolTipIconButton
          title="Move up"
          onClick={props.onMoveDown}
          disabled={props.isFirst}
        >
          <ArrowUpwardIcon />
        </ToolTipIconButton>
        <ToolTipIconButton title="Remove" onClick={props.onDelete}>
          <HighlightOffIcon />
        </ToolTipIconButton>
      </div>
    </div>
  );
};

export default StatementLayout;
