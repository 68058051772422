import React from "react";

//CSS
import ss from "./Gallery.module.css";

const Gallery = (props) => {
  console.log(props.images);
  return <div className={ss.container}>{props.children}</div>;
};

export default Gallery;
