import React from "react";
import { useQuery } from "@apollo/client";
import { QUERY_STATE_LOG } from "../../../../../../../../network/gql/system-monitor";
import StateLogList from "./Layout/StateLogList";
import moment from "moment";

const StateLogListContainer = (props) => {
  const { data } = useQuery(QUERY_STATE_LOG, {
    variables: { id: props.id },
    fetchPolicy: "network-only",
  });

  const logs = data ? modifyLogs(data.stateLogs) : [];

  return <StateLogList logs={logs} />;
};

export default StateLogListContainer;

const modifyLogs = (logs) => {
  const newLogs = [];

  for (let i = 0; i < logs.length; i++) {
    const log = logs[i];
    if (i === logs.length - 1) {
      newLogs.push({
        ...log,
        diff: null,
      });
    } else {
      newLogs.push({
        ...log,
        diff: moment(logs[i + 1].timestamp).diff(log.timestamp, "minutes"),
      });
    }
  }

  return newLogs;
};
