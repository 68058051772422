import { Typography } from "@material-ui/core";
import React from "react";

//css
import ss from "./Row.module.css";

const Row = ({ name, value, unit }) => {
  return (
    <div className={ss.row}>
      <Typography variant="body1">{name}</Typography>

      <Typography variant="body1">{`${value} ${unit}`}</Typography>
    </div>
  );
};

export default Row;
