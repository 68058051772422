import React from "react";
import { Tooltip, IconButton } from "@material-ui/core";

const ToolTipIconButton = (props) => {
  return (
    <Tooltip title={props.title}>
      <span>
        <IconButton onClick={props.onClick} disabled={props.disabled}>
          {props.children}
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default ToolTipIconButton;
