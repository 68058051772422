import React from "react";

//CSS
import ss from "./ImageCard.module.css";

//Components
import Card from "../../../../../../UI/Card/Card";
import ToolTipIconButton from "../../../../../../UI/ToolTipIconButton/ToolTipIconButton";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

const ImageCard = (props) => {
  return (
    <Card className={ss.card}>
      <img
        src={`${process.env.REACT_APP_BACKEND_IMAGES_LOAD}/${props.path}`}
        alt="Bathing place"
        className={ss.image}
      />
      <div className={ss.actions}>
        <ToolTipIconButton title="Primary" onClick={props.onSetImagePrimary}>
          {props.primary ? (
            <StarIcon color="primary" />
          ) : (
            <StarBorderIcon color="primary" />
          )}
        </ToolTipIconButton>
        <ToolTipIconButton title="Delete" onClick={props.onDeleteImage}>
          <DeleteOutlineIcon color="primary" />
        </ToolTipIconButton>
      </div>
    </Card>
  );
};

export default ImageCard;
