export const P_USERS = "/users";
export const P_USERS_ID = "/users/:id";
export const P_HOME = "/home";
export const P_MOBILEAPP = "/mobile-app";
export const P_MOBILEAPP_CONFIGURE = "/mobile-app/configure";
export const P_MOBILEAPP_CONFIGURE_ID = "/mobile-app/configure/:id";
export const P_MOBILEAPP_PLACES = "/mobile-app/places";
export const P_MOBILEAPP_PLACES_INFO_ID = "/mobile-app/places/:placeId/info";
export const P_MOBILEAPP_PLACES_POSTS_ID = "/mobile-app/places/:placeId/posts";
export const P_MOBILEAPP_PLACES_POSTS_EDIT_ID =
  "/mobile-app/places/:placeId/posts/edit/:id";
export const P_MOBILEAPP_VERSION = "/mobile-app/versions";
export const P_LOGIN = "/login";
export const P_PROFILE = "/profile";
export const P_SM = "/system-monitor";
export const P_SM_ID = "/system-monitor/:id";
export const P_SWIMSPOTS = "/swimspots";
export const P_SWIMSPOTS_IFRAME = "/swimspots/iframe";

const R_SYSTEM_ADMIN = "SYSTEM_ADMIN";
const R_MOBILE_APP_UPDATE = "MOBILE_APP_UPDATE";

const pathsPermissions = [
  { path: P_HOME, allowed: [] },
  { path: P_LOGIN, allowed: [] },
  { path: P_PROFILE, allowed: [] },
  { path: P_MOBILEAPP, allowed: [] },
  { path: P_USERS, allowed: [R_SYSTEM_ADMIN] },
  { path: P_USERS_ID, allowed: [R_SYSTEM_ADMIN] },
  {
    path: P_MOBILEAPP_PLACES,
    allowed: [R_MOBILE_APP_UPDATE, R_SYSTEM_ADMIN],
  },
  {
    path: P_MOBILEAPP_PLACES_INFO_ID,
    allowed: [R_MOBILE_APP_UPDATE, R_SYSTEM_ADMIN],
  },
  {
    path: P_MOBILEAPP_PLACES_POSTS_ID,
    allowed: [R_MOBILE_APP_UPDATE, R_SYSTEM_ADMIN],
  },
  {
    path: P_MOBILEAPP_PLACES_POSTS_EDIT_ID,
    allowed: [R_MOBILE_APP_UPDATE, R_SYSTEM_ADMIN],
  },
  { path: P_MOBILEAPP_CONFIGURE, allowed: [R_SYSTEM_ADMIN] },
  { path: P_MOBILEAPP_CONFIGURE_ID, allowed: [R_SYSTEM_ADMIN] },
  { path: P_MOBILEAPP_VERSION, allowed: [R_SYSTEM_ADMIN] },
  { path: P_SM, allowed: [R_SYSTEM_ADMIN] },
  { path: P_SM_ID, allowed: [R_SYSTEM_ADMIN] },
  { path: P_SWIMSPOTS, allowed: [] },
  { path: P_SWIMSPOTS_IFRAME, allowed: [] },
];

export const hasPermission = (path, userPermissions) => {
  const index = pathsPermissions.findIndex((p) => p.path === path);
  if (index < 0) throw Error(`Path does not exist! ${path}`);

  if (pathsPermissions[index].allowed.length === 0) return true;

  return pathsPermissions[index].allowed.some((p) =>
    userPermissions.includes(p)
  );
};

export const isPublic = (path) => {
  const index = pathsPermissions.findIndex((p) => p.path === path);
  if (index < 0) throw Error("Path does not exist!");

  return pathsPermissions[index].allowed.length === 0;
};
