import React from "react";
import { Switch } from "react-router-dom";
import GuardedRoute from "../../PageRouter/GuardedRoute/GuardedRoute";
import { P_SM, P_SM_ID } from "../../../models/user-access/UserAccess";

//Pages
import UnitsPage from "./Units/UnitsPage";
import EditUnitPage from "./EditUnitPage/EditUnitPage";

const SubPagesRouter = () => {
  return (
    <Switch>
      <GuardedRoute path={P_SM_ID}>
        <EditUnitPage />
      </GuardedRoute>
      <GuardedRoute path={P_SM} exact>
        <UnitsPage />
      </GuardedRoute>
    </Switch>
  );
};

export default SubPagesRouter;
