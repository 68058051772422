import React, { Fragment } from "react";
import { Formik } from "formik";
import moment from "moment-timezone";
import * as Yup from "yup";
import * as _ from "lodash";
//css
import ss from "./PostForm.module.css";

//Components
import ValidatedTextField from "../../../../../UI/Form/ValidatedTextField/ValidatedTextField";
import DatePicker from "../../../../../UI/Form/DatePicker/DatePicker";
import StatementsArray, {
  getInitItem as getStatementInit,
} from "../../../../../UI/Form/StatementsArray/StatementsArray";
import DataPointsArray, {
  getInitItem as getDataPointInit,
} from "../../../../../UI/Form/DataPointsArray/DataPointsArray";
import { Button, MenuItem, Typography } from "@material-ui/core";
import ValidationErrorViewer from "../../../../../UI/ValidationErrorViewer/ValidationErrorViewer";
import MenuItemWithIcon from "../../../../../UI/Form/StatementsArray/MenuItemWithIcon/MenuItemWithIcon";
import {
  ICON_CHECK,
  ICON_DANGER,
  ICON_INFO,
  ICON_WARNING,
} from "../../../../../.../../../constants/IconTypes";

/**
 * Validation
 */

const validationSchema = Yup.object().shape({
  date: Yup.date("Invalid date").required("Date is required"),
  statements: Yup.array().of(
    Yup.object({
      icon: Yup.string().required("Required"),
      text: Yup.string()
        .required("Required")
        .min(5, "Too short")
        .max(70, "Too long"),
    })
  ),
  datapoints: Yup.array().of(
    Yup.object({
      label: Yup.string().required("Required").max(50, "Too long"),
      unit: Yup.string().required("Required"),
      value: Yup.number().required("Required"),
      quality: Yup.string().required("Required"),
      rangeMin: Yup.number()
        .required("Required")
        .max(Yup.ref("value"), "Too big"),
      rangeMax: Yup.number()
        .required("Required")
        .min(Yup.ref("value"), "Too small"),
      limitMin: Yup.number().min(Yup.ref("rangeMin"), "Too small"),
      limitMax: Yup.number().max(Yup.ref("rangeMax"), "Too big"),
    })
  ),
  description: Yup.string().max(200, "Too long"),
});

const PostForm = (props) => {
  return (
    <Formik
      initialValues={props.initialValues}
      validationSchema={validationSchema}
      onSubmit={props.onSubmit}
      enableReinitialize
    >
      {({ values, handleSubmit, isSubmitting, initialValues, errors }) => (
        <Fragment>
          <ValidationErrorViewer messages={props.serverErrors} />
          <form onSubmit={handleSubmit} className={ss.form}>
            <DatePicker
              name="date"
              label="Post date"
              format="DD-MM-yyyy"
              maxDate={moment()}
              className={ss.input}
            />
            <Typography variant="h6" color="primary">
              Indicate overall water quality status:
            </Typography>
            <StatementsArray arrayName="statements" items={values.statements}>
              <MenuItem value={ICON_CHECK}>
                <MenuItemWithIcon iconType={ICON_CHECK} text="Good" />
              </MenuItem>
              <MenuItem value={ICON_WARNING}>
                <MenuItemWithIcon iconType={ICON_WARNING} text="Fair" />
              </MenuItem>
              <MenuItem value={ICON_DANGER}>
                <MenuItemWithIcon iconType={ICON_DANGER} text="Bad" />
              </MenuItem>
              <MenuItem value={ICON_INFO}>
                <MenuItemWithIcon iconType={ICON_INFO} text="Information" />
              </MenuItem>
            </StatementsArray>
            <Typography variant="h6" color="primary">
              Water quality variables:
            </Typography>
            <DataPointsArray
              arrayName="datapoints"
              items={values.datapoints}
              errors={errors.datapoints}
            />
            <ValidatedTextField
              type="input"
              name="description"
              label="Description (optional)"
              className={ss.input}
              fullWidth
              rows={4}
              multiline
              variant="outlined"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting || _.isEqual(values, initialValues)}
            >
              {props.label}
            </Button>
          </form>
          {/* <pre>{JSON.stringify(errors, null, 4)}</pre> */}
        </Fragment>
      )}
    </Formik>
  );
};

export const getInitialValues = () => {
  return {
    date: moment(),
    statements: [getStatementInit()],
    datapoints: [getDataPointInit()],
    description: "",
  };
};

export default PostForm;
