import React from "react";
import backgroundImage from "../../../../../assets/backgroundimage.jpg";
import logo from "../../../../../assets/logo_updated.png";

//CSS
import ss from "./HeaderImages.module.css";

const HeaderImages = (props) => {
  return (
    <div className={ss.imgContainer}>
      <img
        src={backgroundImage}
        alt="Background"
        className={ss.backgroundImg}
      />
      <img src={logo} alt="Logo" className={ss.logo} />
    </div>
  );
};

export default HeaderImages;
