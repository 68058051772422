import React from "react";
import * as _ from "lodash";

//css
import ss from "./CardPlacesList.module.css";

//Components
import PlaceCard from "./PlaceCard/PlaceCard";

const CardPlacesList = (props) => {
  const sortedPosts = _.orderBy(props.places, "name", "asc");

  return (
    <div className={ss.container}>
      {sortedPosts.map((p) => (
        <PlaceCard
          key={p.id}
          name={p.name}
          description={p.description}
          imagePath={p.imagePath}
          onEditInfo={props.onEditInfo.bind(null, p.id)}
          onAddPost={props.onAddPost.bind(null, p.id)}
        />
      ))}
    </div>
  );
};

export default CardPlacesList;
